import { upperFirst } from 'lodash-es';

import { useAppSelector } from 'src/app/store';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { DropdownSubmenu } from 'src/common/DropdownMenu/DropdownSubmenu';
import {
  DispatchTripRead,
  NewStatusEnum,
} from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { useTripMenuActions } from '../../../useTripMenuActions';
import { useAllowableNextTripStatuses } from './useAllowableNextTripStatuses';

export function ChangeStatusMenu({ trip }: { trip: DispatchTripRead }) {
  const allowableNextTripStatuses = useAllowableNextTripStatuses(trip.id);
  const menuActions = useTripMenuActions();
  const isMenuActionInProgress = useAppSelector(
    (state) => state.dispatcher.isMenuActionInProgress
  );
  return (
    <DropdownSubmenu triggerText="Change Status">
      {(isMenuActionInProgress || allowableNextTripStatuses.length <= 0) && (
        <div className="flex items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {allowableNextTripStatuses.length > 0 &&
        allowableNextTripStatuses
          .filter((x) => x !== trip.status)
          .map((status) => {
            return (
              <DropdownMenuItem
                key={status}
                onClick={() => {
                  // Special handling to trigger reject modal for Momentm rejected trips
                  if (
                    status === 'rejected' &&
                    trip.intermediary === 'momentm'
                  ) {
                    return menuActions.rejectMarketplaceRequest(trip.id);
                  }

                  return menuActions.overrideStatus(
                    trip.id,
                    status as NewStatusEnum
                  );
                }}
              >
                {upperFirst(status)}
              </DropdownMenuItem>
            );
          })}
    </DropdownSubmenu>
  );
}
