import { Header } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

function useOrderParams(
  header: Header<any, unknown>,
  orderingQueryParam = 'ordering',
  onlyOne = true
): { isActive: boolean; isReversed: boolean; link: string } | null {
  const [params] = useSearchParams();
  let currentOrdering = params.get(orderingQueryParam)?.split(',') || [];

  if (!header.column.columnDef.enableSorting) {
    return null;
  }

  const negatedField = `-${header.id}`;
  const isReversed = currentOrdering.includes(negatedField);
  const isActive = currentOrdering.includes(header.id) || isReversed;

  if (isReversed || !isActive) {
    currentOrdering = currentOrdering.filter((f) => f !== negatedField);
    currentOrdering = [header.id, ...currentOrdering];
  } else {
    currentOrdering = currentOrdering.filter((f) => f !== header.id);
    currentOrdering = [negatedField, ...currentOrdering];
  }

  if (currentOrdering.length && onlyOne) {
    currentOrdering = [currentOrdering[0]];
  }

  // Relative pathing from parent
  const link = `?ordering=${currentOrdering.join(',')}`;

  return {
    isActive,
    isReversed,
    link,
  };
}

export default useOrderParams;
