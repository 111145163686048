import currency from 'currency.js';
import { Controller, useFormContext } from 'react-hook-form';

import { CurrencyInput } from 'src/common/CurrencyInput';
import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';

import {
  InvoiceNestedLineItemWithId,
  useInvoiceFormContext,
} from '../InvoiceFormContext';

interface PriceEditCellProps {
  lineItem: InvoiceNestedLineItemWithId;
}
export function PriceEditCell({ lineItem }: PriceEditCellProps) {
  const { control } = useFormContext<InvoiceRead>();
  const { findLineItemIndex } = useInvoiceFormContext();
  const actualIndex = findLineItemIndex(lineItem);

  return (
    <Controller
      control={control}
      name={`line_items.${actualIndex}.amount_cents`}
      render={({ field }) => {
        return (
          <CurrencyInput
            data-testid="price-input"
            className="w-24"
            value={field.value}
            onChange={(e) => {
              const cents = currency(e.target.value).multiply(100).value;
              field.onChange(cents);
            }}
            allowNegative
          />
        );
      }}
    />
  );
}
