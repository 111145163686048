import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import {
  AssignmentTimeRangeBulkCreateWrite,
  useAssignmentsTimeRangeCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { Modal } from 'src/common/primitives/Modal';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { WizardHeader } from 'src/common/wizard/WizardHeader';

import {
  setSelectedAssignment,
  setShowMultiAssignmentManagementModal,
} from '../assignmentManagement.slice';
import { ActualMultiAssignmentRequestBody } from './ActualMultiAssignmentRequestBody';
import { Crew } from './Crew';
import { generateAddSuccessToast } from './generateAddSuccessToast';
import { generateAssignmentTimeRangeRequestBody } from './generateAssignmentTimeRangeRequestBody';
import { getStepDescription } from './getStepDescription';
import { getStepTitle } from './getStepTitle';
import { FormValues as MultiAssignmentManagementFormStep1FormValues } from './MultiAssignmentManagementFormStep1/FormValues';
import { MultiAssignmentManagementFormStep1 } from './MultiAssignmentManagementFormStep1/MultiAssignmentManagementFormStep1';
import { FormValues as MultiAssignmentManagementFormStep2FormValues } from './MultiAssignmentManagementFormStep2/FormValues';
import { MultiAssignmentManagementFormStep2 } from './MultiAssignmentManagementFormStep2/MultiAssignmentManagementFormStep2';

export function MultiAssignmentManagementForm() {
  const dispatch = useDispatch();
  const [crew, setCrew] = useState<Crew | undefined>();
  const [createBulkAssignments] = useAssignmentsTimeRangeCreateMutation();

  const isOpen = useAppSelector(
    (state) => state.assignmentManagement.showMultiAssignmentManagementModal
  );

  const [serverErrors, setServerErrors] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const nextToastId = useAppSelector((state) => state.toast.nextId);

  const closeModal = () => {
    dispatch(setShowMultiAssignmentManagementModal(false));
    dispatch(setSelectedAssignment(undefined));
    setStep(1);
  };

  const onSubmitStepOne = (
    data: MultiAssignmentManagementFormStep1FormValues
  ) => {
    setCrew({
      ...data,
    });
    setStep(2);
  };

  const onSubmitStepTwo = async (
    data: MultiAssignmentManagementFormStep2FormValues
  ) => {
    setLoading(true);
    setServerErrors(null);
    try {
      const requestBody: ActualMultiAssignmentRequestBody =
        generateAssignmentTimeRangeRequestBody(data);

      // Validate assigned days/hours
      if (!requestBody.assigned_days.length) {
        setServerErrors(
          'Error: No time range provided for the selected day(s).'
        );
        setLoading(false);
        return;
      }

      await createBulkAssignments({
        // TODO: AssignmentTimeRangeBulkCreate is not rep of the actual req in that the req is
        // normalized but the generated code uses full entities like AssignmentDriver
        // Either going to have to create a custom hook or fix the json spec
        assignmentTimeRangeBulkCreate:
          requestBody as unknown as AssignmentTimeRangeBulkCreateWrite,
      }).unwrap();
      closeModal();
      dispatch(show(generateAddSuccessToast(nextToastId)));
    } catch (error) {
      const formattedErr = formatServerError(error);
      setServerErrors(formattedErr);
    }
    setLoading(false);
  };

  const title = getStepTitle(step);
  const description = getStepDescription(step);
  return (
    <Modal
      open={isOpen}
      setOpen={(val) => dispatch(setShowMultiAssignmentManagementModal(val))}
      onInteractOutside={(e) => {
        e.preventDefault();
      }}
    >
      <div className="max-w-xl">
        <WizardHeader
          title={title}
          description={description}
          setStep={setStep}
          wizardState={{
            step,
            totalSteps: 2,
          }}
        />
        {step === 1 && (
          <MultiAssignmentManagementFormStep1
            onSubmit={onSubmitStepOne}
            onCancel={() => {
              setServerErrors(null);
              closeModal();
            }}
            loading={loading}
            serverErrors={serverErrors}
          />
        )}
        {step === 2 && (
          <MultiAssignmentManagementFormStep2
            onSubmit={onSubmitStepTwo}
            onCancel={() => {
              setServerErrors(null);
              closeModal();
            }}
            loading={loading}
            serverErrors={serverErrors}
            crew={crew}
          />
        )}
      </div>
    </Modal>
  );
}
