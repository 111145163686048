import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';

import { ReportsFilters } from '../ReportsFilters';
import { ReportsNav } from '../ReportsNav';
import { DriverReportsContent } from './DriverReportsContent';

export function DriverReportsRoute() {
  return (
    <ToolbarAndContentLayout
      preContent={<ReportsNav />}
      content={<DriverReportsContent />}
      actionSection={<ReportsFilters />}
    />
  );
}
