import { Auth } from 'aws-amplify';

import type { CognitoUser } from '@aws-amplify/auth/lib';

export const getAccessToken = async (): Promise<string> => {
  const user: CognitoUser | any = await Auth.currentAuthenticatedUser();
  if (!user) {
    throw new Error('User is not authenticated');
  }
  return user.getSignInUserSession()?.getAccessToken().getJwtToken() || '';
};
