import { PatchedOrganizationSettings } from 'src/common/external/bambi-api/bambiApi';

export const formValuesToPatchedOrganization = (
  formValues: any
): PatchedOrganizationSettings => {
  return {
    headquarters_location: formValues.headquarters_location || null,
    dispatch_phone_number: formValues.dispatch_phone_number || undefined,
    dispatch_text_number: formValues.dispatch_text_number || undefined,
    tax_id: formValues.tax_id || '',
    should_avoid_toll_roads: formValues.should_avoid_toll_roads || false,
    ambulatory_load_seconds: formValues.ambulatory_load_seconds || 0,
    ambulatory_unload_seconds: formValues.ambulatory_unload_seconds || 0,
    wheelchair_load_seconds: formValues.wheelchair_load_seconds || 0,
    wheelchair_unload_seconds: formValues.wheelchair_unload_seconds || 0,
    wheelchair_xl_load_seconds: formValues.wheelchair_xl_load_seconds || 0,
    wheelchair_xl_unload_seconds: formValues.wheelchair_xl_unload_seconds || 0,
    broda_chair_load_seconds: formValues.broda_chair_load_seconds || 0,
    broda_chair_unload_seconds: formValues.broda_chair_unload_seconds || 0,
    geri_chair_load_seconds: formValues.geri_chair_load_seconds || 0,
    geri_chair_unload_seconds: formValues.geri_chair_unload_seconds || 0,
    stretcher_load_seconds: formValues.stretcher_load_seconds || 0,
    stretcher_unload_seconds: formValues.stretcher_unload_seconds || 0,
    pickup_default_early_seconds: formValues.pickup_default_early_seconds || 0,
    pickup_default_late_seconds: formValues.pickup_default_late_seconds || 0,
    pickup_appointment_early_seconds:
      formValues.pickup_appointment_early_seconds || 0,
    pickup_appointment_late_seconds:
      formValues.pickup_appointment_late_seconds || 0,
    pickup_will_call_early_seconds:
      formValues.pickup_will_call_early_seconds || 0,
    pickup_will_call_late_seconds:
      formValues.pickup_will_call_late_seconds || 0,
    appointment_early_arrival_window_seconds:
      formValues.appointment_early_arrival_window_seconds || 0,
    standard_transport_time_start:
      formValues.standard_transport_time_start || null,
    standard_transport_time_end: formValues.standard_transport_time_end || null,
    requires_inspection_after_assignment_start:
      formValues.requires_inspection_after_assignment_start || false,
    requires_inspection_before_assignment_end:
      formValues.requires_inspection_before_assignment_end || false,
    requires_signature_pickup_passenger:
      formValues.requires_signature_pickup_passenger || false,
    requires_signature_dropoff_passenger:
      formValues.requires_signature_dropoff_passenger || false,
    requires_signature_pickup_driver:
      formValues.requires_signature_pickup_driver || false,
    requires_signature_dropoff_driver:
      formValues.requires_signature_dropoff_driver || false,
    default_trip_space_type: formValues.default_trip_space_type || undefined,
    default_trip_service_type:
      formValues.default_trip_service_type || undefined,
    facility_trip_request_lead_time_seconds:
      formValues.facility_trip_request_lead_time_seconds || 0,
    cancellation_fee_window_hours:
      formValues.cancellation_fee_window_hours || 0,
    is_pricing_v1_enabled: formValues.is_pricing_v1_enabled || false,
    can_associate_cancel_trips: formValues.can_associate_cancel_trips || false,
    can_associate_activate_will_call_trips:
      formValues.can_associate_activate_will_call_trips || false,
  };
};
