import { SettingsFormField } from './components/SettingsFormField';
import { SettingsRowHeader } from './components/SettingsRowHeader';

export default function PermissionSettings() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-4 border-b border-gray-900/10 py-5 md:grid-cols-4">
      <SettingsRowHeader
        title="Permissions"
        description="Permissions for members of the organization."
      />

      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 md:col-span-3">
        <div>
          <SettingsFormField
            label="Allow drivers to cancel trips"
            fieldId="can_associate_cancel_trips"
            type="checkbox"
          />
        </div>
        <div>
          <SettingsFormField
            label="Allow drivers to activate will-call trips"
            fieldId="can_associate_activate_will_call_trips"
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
}
