import { faker } from '@faker-js/faker';
import { Factory } from 'rosie';

import type { DriverReport } from 'src/features/reports/DriverReportsRoute/DriverReport';
import { fakeNoun } from 'src/test/util/faker';

export const DriverReportFactory = Factory.define<DriverReport>('DriverReport')
  .sequence('driver_id', () => faker.datatype.uuid())
  .attrs({
    driver_name: fakeNoun,
    estimated_revenue_cents: faker.datatype.number,
    estimated_loaded_miles: faker.datatype.number,
    trips_completed: faker.datatype.number,
    trips_cancelled: faker.datatype.number,
    percent_on_time: () => faker.datatype.float({ min: 0, max: 100 }),
  });
