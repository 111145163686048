import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import { SimpleTooltip } from 'src/common/primitives/Tooltip';
import { useUserRoles } from 'src/features/auth/useUserRoles';

interface EditTripMenuItemProps {
  trip: TripRead;
  onViewEdit: (tripId: string) => void;
}

export default function EditTripMenuItem({
  trip,
  onViewEdit,
}: EditTripMenuItemProps) {
  const { isRideOrderingPortalUser } = useUserRoles();

  const editDisabledForRideOrderingPortalUser =
    isRideOrderingPortalUser && trip.status !== 'requested';

  if (editDisabledForRideOrderingPortalUser) {
    return (
      <SimpleTooltip
        trigger={
          <DropdownMenuItem className="!text-gray-300">Edit</DropdownMenuItem>
        }
        content="Editing is only available for requested trips"
      />
    );
  }

  return (
    <DropdownMenuItem onClick={() => onViewEdit(trip.id)}>
      Edit
    </DropdownMenuItem>
  );
}
