import { useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import {
  TripRead,
  useTripsBulkCancelReasonsCreateQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

/**
 *
 * This is meant to be used with bulk cancel when multiple trips have been selected
 * and we only want to make a single request to get the cancel reasons for all selected trips
 */
export function useTripCancelReasonsForTripWithBulkCancel(
  tripId: string
): ReturnType<typeof useTripsBulkCancelReasonsCreateQuery> & {
  reasons: SelectOption[];
} {
  const selectedTripRowsInState: Record<string, TripRead | null> =
    useAppSelector((state) => state.trip.selectedTripRows);

  const selectedTripRows = useMemo(() => {
    return Object.values(selectedTripRowsInState).filter(
      (trip) => trip !== null
    ) as TripRead[];
  }, [selectedTripRowsInState]);

  const bulkCancelResultWithSelectedReasons =
    useTripsBulkCancelReasonsCreateQuery(
      {
        bulkTripCancelReasons: {
          trip_ids: selectedTripRows.map((trip) => trip.id),
        },
      },
      {
        skip: selectedTripRows.length === 0,
        selectFromResult: (result) => {
          const rawTripReasons =
            result.data?.results.find((result) => {
              return result.trip_id === tripId;
            })?.cancel_reasons || [];
          const reasons: SelectOption[] = rawTripReasons.map((reasonObj) => ({
            value: reasonObj.value || '',
            label: reasonObj.label,
          }));

          return {
            ...result,
            reasons,
          };
        },
      }
    );

  return bulkCancelResultWithSelectedReasons;
}
