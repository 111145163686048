import { Card } from 'src/common/primitives/Card/Card';

export type MetricCardProps = {
  title: string;
  formattedValue?: string;
  children?: React.ReactNode;
};

export function MetricCard({
  title,
  formattedValue,
  children,
}: MetricCardProps) {
  return (
    <Card>
      <dt
        className="truncate text-sm font-medium text-gray-500"
        data-testid="stat-title"
      >
        {title}
      </dt>
      <dd className="flex flex-col gap-1" data-testid="stat-value-container">
        {formattedValue && (
          <div
            className="mt-1 text-3xl font-semibold tracking-tight text-gray-900"
            data-testid="stat-formatted-value"
          >
            {formattedValue}
          </div>
        )}
        {children}
      </dd>
    </Card>
  );
}
