import { OrganizationSettings } from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';

export const assignDefaultFormValues = (
  organizationSettings?: OrganizationSettings
) => {
  if (!organizationSettings) return defaultFormValues;

  return {
    headquarters_location: organizationSettings.headquarters_location
      ? organizationSettings.headquarters_location
      : defaultFormValues.headquarters_location,
    dispatch_phone_number:
      organizationSettings.dispatch_phone_number ||
      defaultFormValues.dispatch_phone_number,
    dispatch_text_number:
      organizationSettings.dispatch_text_number ||
      defaultFormValues.dispatch_text_number,
    tax_id: organizationSettings.tax_id || defaultFormValues.tax_id,
    should_avoid_toll_roads:
      organizationSettings.should_avoid_toll_roads ||
      defaultFormValues.should_avoid_toll_roads,
    ambulatory_load_seconds:
      organizationSettings.ambulatory_load_seconds ||
      defaultFormValues.ambulatory_load_seconds,
    ambulatory_unload_seconds:
      organizationSettings.ambulatory_unload_seconds ||
      defaultFormValues.ambulatory_unload_seconds,
    wheelchair_load_seconds:
      organizationSettings.wheelchair_load_seconds ||
      defaultFormValues.wheelchair_load_seconds,
    wheelchair_unload_seconds:
      organizationSettings.wheelchair_unload_seconds ||
      defaultFormValues.wheelchair_unload_seconds,
    wheelchair_xl_load_seconds:
      organizationSettings.wheelchair_xl_load_seconds ||
      defaultFormValues.wheelchair_xl_load_seconds,
    wheelchair_xl_unload_seconds:
      organizationSettings.wheelchair_xl_unload_seconds ||
      defaultFormValues.wheelchair_xl_unload_seconds,
    broda_chair_load_seconds:
      organizationSettings.broda_chair_load_seconds ||
      defaultFormValues.broda_chair_load_seconds,
    broda_chair_unload_seconds:
      organizationSettings.broda_chair_unload_seconds ||
      defaultFormValues.broda_chair_unload_seconds,
    geri_chair_load_seconds:
      organizationSettings.geri_chair_load_seconds ||
      defaultFormValues.geri_chair_load_seconds,
    geri_chair_unload_seconds:
      organizationSettings.geri_chair_unload_seconds ||
      defaultFormValues.geri_chair_unload_seconds,
    stretcher_load_seconds:
      organizationSettings.stretcher_load_seconds ||
      defaultFormValues.stretcher_load_seconds,
    stretcher_unload_seconds:
      organizationSettings.stretcher_unload_seconds ||
      defaultFormValues.stretcher_unload_seconds,
    pickup_default_early_seconds:
      organizationSettings.pickup_default_early_seconds ||
      defaultFormValues.pickup_default_early_seconds,
    pickup_default_late_seconds:
      organizationSettings.pickup_default_late_seconds ||
      defaultFormValues.pickup_default_late_seconds,
    pickup_appointment_early_seconds:
      organizationSettings.pickup_appointment_early_seconds ||
      defaultFormValues.pickup_appointment_early_seconds,
    pickup_appointment_late_seconds:
      organizationSettings.pickup_appointment_late_seconds ||
      defaultFormValues.pickup_appointment_late_seconds,
    pickup_will_call_early_seconds:
      organizationSettings.pickup_will_call_early_seconds ||
      defaultFormValues.pickup_will_call_early_seconds,
    pickup_will_call_late_seconds:
      organizationSettings.pickup_will_call_late_seconds ||
      defaultFormValues.pickup_will_call_late_seconds,
    appointment_early_arrival_window_seconds:
      organizationSettings.appointment_early_arrival_window_seconds ||
      defaultFormValues.appointment_early_arrival_window_seconds,
    standard_transport_time_start:
      organizationSettings.standard_transport_time_start ||
      defaultFormValues.standard_transport_time_start,
    standard_transport_time_end:
      organizationSettings.standard_transport_time_end ||
      defaultFormValues.standard_transport_time_end,
    requires_inspection_after_assignment_start:
      organizationSettings.requires_inspection_after_assignment_start ||
      defaultFormValues.requires_inspection_after_assignment_start,
    requires_inspection_before_assignment_end:
      organizationSettings.requires_inspection_before_assignment_end ||
      defaultFormValues.requires_inspection_before_assignment_end,
    requires_signature_pickup_passenger:
      organizationSettings.requires_signature_pickup_passenger ||
      defaultFormValues.requires_signature_pickup_passenger,
    requires_signature_dropoff_passenger:
      organizationSettings.requires_signature_dropoff_passenger ||
      defaultFormValues.requires_signature_dropoff_passenger,
    requires_signature_pickup_driver:
      organizationSettings.requires_signature_pickup_driver ||
      defaultFormValues.requires_signature_pickup_driver,
    requires_signature_dropoff_driver:
      organizationSettings.requires_signature_dropoff_driver ||
      defaultFormValues.requires_signature_dropoff_driver,
    default_trip_space_type: organizationSettings.default_trip_space_type || '',
    default_trip_service_type:
      organizationSettings.default_trip_service_type || '',
    facility_trip_request_lead_time_seconds:
      organizationSettings.facility_trip_request_lead_time_seconds ||
      defaultFormValues.facility_trip_request_lead_time_seconds,
    cancellation_fee_window_hours:
      organizationSettings.cancellation_fee_window_hours ||
      defaultFormValues.cancellation_fee_window_hours,
    is_pricing_v1_enabled:
      organizationSettings.is_pricing_v1_enabled ||
      defaultFormValues.is_pricing_v1_enabled,
    logo: organizationSettings.logo ?? '',
    can_associate_cancel_trips:
      organizationSettings.can_associate_cancel_trips ||
      defaultFormValues.can_associate_cancel_trips,
    can_associate_activate_will_call_trips:
      organizationSettings.can_associate_activate_will_call_trips ||
      defaultFormValues.can_associate_activate_will_call_trips,
  };
};
