import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import 'src/app/app.css';

import { DesktopNavLinkList } from './DesktopNavLinkList';
import { MobileNav } from './MobileNav';
import { NavLogo } from './NavLogo';
import { RightNav } from './RightNav';
import { TopLevelNavItemContext } from './TopLevelNavItemContext';
import { useNavBarItems } from './useNavBarItems';

export function NavBar() {
  const { navBarItems } = useNavBarItems();

  return (
    <TopLevelNavItemContext.Provider value={navBarItems}>
      <Disclosure
        as="nav"
        className="sticky top-0 z-20 bg-white shadow"
        data-testid="top-level-nav"
      >
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                  <Disclosure.Button className="focus:ring-indigo-500 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-grow items-center">
                  <div className="mr-2 flex items-center pl-12 lg:pl-0">
                    <NavLogo />
                  </div>
                  <div className="hidden flex-grow justify-center lg:flex">
                    <DesktopNavLinkList />
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 z-50 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <RightNav />
                </div>
              </div>
            </div>

            <MobileNav open={open} />
          </>
        )}
      </Disclosure>
    </TopLevelNavItemContext.Provider>
  );
}
