import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Select } from 'src/common/primitives/Select';
import { tripSlice } from 'src/features/trip/trip.slice';

import { useTripCancelReasonsForTripWithBulkCancel } from './useTripCancelReasonsForTripWithBulkCancel';

export function TripReasonCell({ tripId }: { tripId: string }) {
  const { reasons } = useTripCancelReasonsForTripWithBulkCancel(tripId);
  const dispatch = useAppDispatch();
  const selectedTripReasons = useAppSelector(
    (state) => state.trip.bulkCancelModal.selectedTripReasons
  );

  return (
    <div data-testid={`cancel-reason-select-for-trip-${tripId}`}>
      <Select
        options={reasons}
        onChange={(option) => {
          if (option) {
            dispatch(
              tripSlice.actions.setBulkCancelReasons({
                ...selectedTripReasons,
                [tripId]: option.value,
              })
            );
          }
        }}
      />
    </div>
  );
}
