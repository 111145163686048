import { useState } from 'react';

import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';

export const useCancellationLogic = (
  selectedTrip: TripRead | null,
  closeModal: () => void
) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const tripRepository = useTripRepository();

  const handleCancelTrip = async () => {
    setLoading(true);
    try {
      if (!selectedTrip?.id) {
        setLoading(false);
        return;
      }

      await tripRepository.cancel(selectedTrip.id, reason);
      closeModal();
    } catch (error) {
      handleCancellationError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancellationError = (error: any) => {
    const formattedError = formatServerError(error);
    setError(formattedError);
  };

  return {
    reason,
    setReason,
    error,
    setError,
    loading,
    handleCancelTrip,
  };
};
