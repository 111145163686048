import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { ReportContentLayout } from '../ReportContentLayout';
import { DriverReportsDataGrid } from './DriverReportsDataGrid';
import { useDriverReports } from './useDriverReports';

export function DriverReportsContent() {
  const { isLoading, isFetching, driverReports } = useDriverReports();

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-center pt-24">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <ReportContentLayout>
      <div className="col-span-full">
        <DriverReportsDataGrid driverReports={driverReports} />
      </div>
    </ReportContentLayout>
  );
}
