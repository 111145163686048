import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';

import { ReportContentLayout } from '../ReportContentLayout';
import { PayerReportsDataGrid } from './PayerReportsDataGrid';
import { usePayerReports } from './usePayerReports';

export function PayerReportsContent() {
  const { isLoading, isFetching, payerReports } = usePayerReports();

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-center pt-24">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <ReportContentLayout>
      <div className="col-span-full">
        <PayerReportsDataGrid payerReports={payerReports} />
      </div>
    </ReportContentLayout>
  );
}
