import { useMemo } from 'react';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'src/app/store';
import {
  DriverRead,
  useDriversListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';

interface DriverColumnFilterProps {
  open: boolean;
  onClose: () => void;
  selected: string[];
  setSelectedAction: ActionCreatorWithPayload<string[], string>;
}

export function DriverColumnFilter({
  open,
  onClose,
  selected,
  setSelectedAction,
}: DriverColumnFilterProps) {
  const dispatch = useAppDispatch();
  const { data } = useDriversListQuery({});

  const selectedValues: DriverRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) => selected.includes(v.id));
  }, [data, selected]);

  return (
    <MultiSelectFilterPopover
      open={open}
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
      onCancel={onClose}
      onChange={(values) =>
        dispatch(setSelectedAction(values.map((v) => v.id)))
      }
      triggerElement={<div data-testid="driver-column-filter-trigger" />}
    />
  );
}
