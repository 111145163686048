import { useFeature } from 'src/app/FeatureToggle';
import { useRequestedTripBadge } from 'src/common/NavBar/useRequestedTripBadge';

type Tab = { name: string; href: string; badgeCount?: number };

export function useRideOrderMarketplaceRoutes(tabs: Tab[]) {
  const { isEnabled: isROMEnabled, isLoading: isROMLoading } = useFeature(
    'ride ordering marketplace'
  );
  const { showRequestedTripBadge, requestedTrips } =
    useRequestedTripBadge(false);

  if (!isROMEnabled || isROMLoading) {
    return { tabs };
  }

  const updatedTabs = [...tabs];
  const allRequestsTab = createAllRequestsTab(
    showRequestedTripBadge,
    requestedTrips?.count
  );

  const allRequestsIndex = updatedTabs.findIndex(
    (tab) => tab.name === 'All Requests'
  );

  if (allRequestsIndex === -1) {
    updatedTabs.push(allRequestsTab);
  } else {
    updatedTabs[allRequestsIndex] = allRequestsTab;
  }

  return { tabs: updatedTabs };
}

const createAllRequestsTab = (showBadge: boolean, badgeCount?: number): Tab => {
  const tab: Tab = { name: 'All Requests', href: '/trips/requests' };

  if (showBadge && badgeCount) {
    tab.badgeCount = badgeCount;
  }

  return tab;
};
