import { DocumentIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';

import { DataGrid } from 'src/common/DataGrid';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import type { DriverReport } from './DriverReport';

export function DriverReportsDataGrid({
  driverReports,
}: {
  driverReports: DriverReport[];
}) {
  const columnHelper = createColumnHelper<DriverReport>();
  const columns = [
    columnHelper.accessor('driver_name', {
      id: 'driver_name',
      header: 'Driver',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('estimated_revenue_cents', {
      id: 'estimated_revenue_cents',
      header: 'Estimated Revenue',
      cell: (row) => defaultCurrencyFormat(row.getValue()),
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('estimated_loaded_miles', {
      id: 'estimated_loaded_miles',
      header: 'Estimated Loaded Miles',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('trips_completed', {
      id: 'trips_completed',
      header: 'Trips Completed',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('trips_cancelled', {
      id: 'trips_cancelled',
      header: 'Trips Cancelled',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('percent_on_time', {
      id: 'percent_on_time',
      header: 'Percent On Time',
      cell: (row) => `${row.getValue()}%`,
      meta: {
        enableHeaderMenu: false,
      },
    }),
  ];

  return (
    <DataGrid
      tableId={`driver-reports-table`}
      columns={columns}
      data={driverReports}
      totalCount={driverReports.length}
      emptyState={
        <EmptyState
          Icon={<DocumentIcon className="h-6 w-6" />}
          entityName="Driver Reports"
          description="No driver reports found for the selected filters."
        />
      }
      dataTestId="driver-reports-table"
    />
  );
}
