import { DocumentIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';

import { DataGrid } from 'src/common/DataGrid';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { EmptyState } from 'src/common/primitives/EmptyState/EmptyState';

import type { PayerReport } from './PayerReport';

export function PayerReportsDataGrid({
  payerReports,
}: {
  payerReports: PayerReport[];
}) {
  const columnHelper = createColumnHelper<PayerReport>();
  const columns = [
    columnHelper.accessor('payer_name', {
      id: 'payer_name',
      header: 'Payer',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('estimated_revenue_cents', {
      id: 'estimated_revenue_cents',
      header: 'Estimated Revenue',
      cell: (row) => defaultCurrencyFormat(row.getValue()),
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('estimated_loaded_miles', {
      id: 'estimated_loaded_miles',
      header: 'Estimated Loaded Miles',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('trips_completed', {
      id: 'trips_completed',
      header: 'Trips Completed',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('trips_cancelled', {
      id: 'trips_cancelled',
      header: 'Trips Cancelled',
      meta: {
        enableHeaderMenu: false,
      },
    }),
    columnHelper.accessor('percent_on_time', {
      id: 'percent_on_time',
      header: 'Percent On Time',
      cell: (row) => `${row.getValue()}%`,
      meta: {
        enableHeaderMenu: false,
      },
    }),
  ];

  return (
    <DataGrid
      tableId={`payer-reports-table`}
      columns={columns}
      data={payerReports}
      totalCount={payerReports.length}
      emptyState={
        <EmptyState
          Icon={<DocumentIcon className="h-6 w-6" />}
          entityName="Payer Reports"
          description="No payer reports found for the selected filters."
        />
      }
      dataTestId="payer-reports-table"
    />
  );
}
