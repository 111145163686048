import { TabNavigation } from 'src/common/TabNavigation';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import { useRideOrderMarketplaceRoutes } from './rom/useRideOrderMarketplaceRoutes';

const tabs = [
  { name: 'Trips', href: '/trips' },
  { name: 'Subscriptions', href: '/trips/subscriptions' },
];

const rideOrderingPortalTabs = [{ name: 'Trips', href: '/trips' }];

export function TripsNav() {
  const shouldUseRideOrderingPortalRoutes =
    useShouldShowRideOrderingPortalFeatures();

  const { tabs: nonRideOrderingTabs } = useRideOrderMarketplaceRoutes(tabs);

  return (
    <TabNavigation
      tabs={
        shouldUseRideOrderingPortalRoutes
          ? rideOrderingPortalTabs
          : nonRideOrderingTabs
      }
    />
  );
}
