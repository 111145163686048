import { classNames } from 'src/common/classNames';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  PriceSummary,
  TripPriceSummaryRead,
} from 'src/common/external/bambi-api/bambiApi';

import { usePriceSummaryTableData } from './usePriceSummaryTableData';

interface PriceSummaryTableProps {
  summary: TripPriceSummaryRead['price_summary'] | PriceSummary;
  includeFooter?: boolean;
  headerClassName?: string;
  cellClassName?: string;
  centerSummaryName?: boolean;
  rightAlignPrice?: boolean;
}

export function PriceSummaryTable({
  summary,
  headerClassName,
  cellClassName,
  includeFooter = true,
  centerSummaryName = true,
  rightAlignPrice = false,
}: PriceSummaryTableProps) {
  const trClass = 'border-b border-gray-300 even:bg-gray-100 odd:bg-white';

  const { feeSummaries, getLabelDetails } = usePriceSummaryTableData(summary);

  return (
    <table
      className="min-w-full divide-y divide-gray-300 text-sm"
      data-testid="price-summary-table"
    >
      <thead>
        <tr className={trClass}>
          <th
            className={classNames(
              'p-2 text-lg',
              centerSummaryName ? 'text-center' : '',
              headerClassName
            )}
            colSpan={4}
          >
            {summary?.name || 'Pricing Summary'}
          </th>
          <th
            className={classNames(
              'p-2',
              headerClassName,
              rightAlignPrice ? 'text-right' : ''
            )}
          >
            Rate
          </th>
          <th
            className={classNames(
              'p-2',
              headerClassName,
              rightAlignPrice ? 'text-right' : ''
            )}
          >
            Subtotal
          </th>
        </tr>
      </thead>
      <tbody>
        {feeSummaries
          .filter((fee) => !!fee.data)
          .map((fee, index) =>
            fee.data && fee.subTotal ? (
              <tr key={index} className={trClass}>
                <td className={classNames('py-2 px-4', cellClassName)}>
                  {fee.label}
                </td>
                {getLabelDetails(fee.label, fee.data)}
                <td
                  className={classNames(
                    'py-2 px-4 font-mono',
                    rightAlignPrice ? 'text-right' : '',
                    cellClassName
                  )}
                >
                  {fee.data.rate ? defaultCurrencyFormat(fee.data.rate) : '--'}
                </td>
                <td
                  className={classNames(
                    'py-2 px-4 font-mono',
                    rightAlignPrice ? 'text-right' : '',
                    cellClassName
                  )}
                >
                  {defaultCurrencyFormat(fee.data.sub_total)}
                </td>
              </tr>
            ) : null
          )}
      </tbody>
      {includeFooter ? (
        <tfoot>
          <tr>
            <td className="px-4 pt-4" colSpan={4}></td>
            <td className="pt-4 font-mono text-lg font-bold">Total</td>
            <td className="px-2 pt-4 font-mono text-lg font-bold">
              {defaultCurrencyFormat(summary?.total || 0)}
            </td>
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
}
