import { useCallback, useMemo } from 'react';

import {
  CloudArrowDownIcon,
  CurrencyDollarIcon,
  PencilIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';

import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';
import { useLazyGetInvoicesExportListAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { Button } from 'src/common/primitives/Button';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import {
  editInvoice,
  setInvoiceModalOpen,
  setPayModalOpen,
} from '../../invoice.slice';
import { PrintableInvoiceTrigger } from '../../print/PrintableInvoice';
import { useInvoiceTotal } from '../../useInvoiceTotal';
import { DeleteInvoiceTrigger } from './DeleteInvoiceTrigger';

export function ManageCell({ invoice }: { invoice: InvoiceRead }) {
  const dispatch = useDispatch();
  const { invoiceTotalDue } = useInvoiceTotal(invoice);

  const handleEditInvoice = useCallback(() => {
    dispatch(editInvoice(invoice));
    dispatch(setInvoiceModalOpen(true));
  }, [dispatch, invoice]);

  const handleShowPay = useCallback(() => {
    dispatch(editInvoice(invoice));
    dispatch(setPayModalOpen(true));
  }, [dispatch, invoice]);

  const [trigger] = useLazyGetInvoicesExportListAsCsvQuery();
  const handleExportInvoice = useCallback(async () => {
    try {
      await trigger({
        invoice: invoice.id,
        format: 'csv',
      }).unwrap();
    } catch (error) {
      dispatch(
        show({
          type: 'error',
          title: 'There was an error downloading the data.',
          description: formatServerError(error),
        })
      );
    }
  }, [dispatch, invoice, trigger]);

  const canDeleteInvoice = useMemo(() => {
    const hasPayments = !!(invoice.payments?.length ?? 0);

    return !hasPayments;
  }, [invoice.payments]);

  return (
    <div className="flex flex-row items-center justify-end">
      <Button
        dataTestId={`edit-invoice-${invoice.id}`}
        variant="icon"
        ariaLabel={`Edit Invoice: ${invoice.number}`}
        onClick={() => handleEditInvoice()}
      >
        <PencilIcon className="h-[20px] w-[24px]" />
      </Button>
      <Button
        dataTestId={`pay-invoice-${invoice.id}`}
        variant="icon"
        onClick={() => handleShowPay()}
        disabled={invoiceTotalDue === 0}
        ariaLabel={`Pay Invoice: ${invoice.number}`}
      >
        <CurrencyDollarIcon className="w-[24px]" />
      </Button>
      <Button
        dataTestId={`export-invoice-${invoice.id}`}
        variant="icon"
        onClick={handleExportInvoice}
        ariaLabel={`Export Invoice: ${invoice.number}`}
      >
        <CloudArrowDownIcon className="w-[24px]" />
      </Button>
      <PrintableInvoiceTriggerWithFormContext invoice={invoice} />
      <DeleteInvoiceTrigger
        invoiceId={invoice.id}
        invoiceNumber={invoice.number}
        disabled={!canDeleteInvoice}
      />
    </div>
  );
}

function PrintableInvoiceTriggerWithFormContext({
  invoice,
}: {
  invoice: InvoiceRead;
}) {
  return (
    <PrintableInvoiceTrigger
      invoice={invoice}
      variant="icon"
      dataTestId={`print-invoice-${invoice.id}`}
      ariaLabel={`Print Invoice: ${invoice.number}`}
    >
      <PrinterIcon className="w-[24px]" />
    </PrintableInvoiceTrigger>
  );
}
