import { useEffect } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import { FormFieldTextArea } from 'src/common/FormField/FormFieldTextArea';
import { validateNoCC } from 'src/common/FormField/validators/creditCardValidator';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal } from 'src/common/primitives/Modal';
import { Well } from 'src/common/primitives/Well/Well';

import { InvoiceForm } from '../forms/InvoiceForm';
import { PaymentsSection } from '../management/PaymentsSection';
import { HydratedInvoice } from '../useHydratedInvoice';
import { useInvoiceTotal } from '../useInvoiceTotal';

export type InvoiceModalProps = {
  open: boolean;
  cancelText?: string;
  confirmText?: string;
  title?: string;
  description?: string;
  loading?: boolean;
  initialLoading?: boolean;
  error?: string | string[];
  icon?: React.ReactNode;
  testId?: string;
  nextInvoiceNumber?: string;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  onUpsert: (invoice: HydratedInvoice) => void;
  onPay: (invoice: HydratedInvoice) => void;
};

export function InvoiceModal({
  open,
  cancelText = 'Cancel',
  confirmText = 'Create Invoice',
  loading = false,
  initialLoading = true,
  nextInvoiceNumber,
  setOpen,
  onCancel,
  onUpsert,
  onPay,
}: InvoiceModalProps) {
  const invoice = useAppSelector((state) => state.invoice.editingInvoice);

  const form = useFormContext<HydratedInvoice>();

  const lineItems = useWatch({
    control: form.control,
    name: 'line_items',
  });

  const { invoiceTotal, invoiceTotalDue } = useInvoiceTotal(invoice, lineItems);

  // Assign autogenerated invoice number if we don't have one
  useEffect(() => {
    if (!invoice?.number && nextInvoiceNumber) {
      form.setValue('number', nextInvoiceNumber);
    }
  }, [nextInvoiceNumber, invoice?.number, form]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentClassnames="w-full max-w-[60vw]"
      testId="invoice-modal"
    >
      {initialLoading ? (
        <div className="flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <InvoiceForm />
          <div
            className="mt-4 flex flex-row items-center justify-end gap-8"
            data-testid="invoice-total-container"
          >
            {lineItems.length ? (
              <>
                <div>Total:</div>
                <div className="text-xl">
                  {defaultCurrencyFormat(invoiceTotal)}
                </div>
              </>
            ) : null}
          </div>
          <Well>
            <strong>Notes</strong>
            <Controller
              control={form.control}
              name="notes"
              rules={{
                validate: (value) => validateNoCC(value ?? ''),
              }}
              render={({ field }) => (
                <FormFieldTextArea
                  inputProps={{
                    value: field.value,
                    onChange: field.onChange,
                    id: 'invoice_notes',
                  }}
                  type="text"
                />
              )}
            />
          </Well>
          {invoice?.payments?.length ? (
            <PaymentsSection
              payments={invoice.payments}
              invoiceTotalDue={invoiceTotalDue}
            />
          ) : null}
          <div className="mt-4 flex flex-row justify-between">
            <Button
              dataTestId="cancelBtn"
              className="sm:col-span-2"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
            <div className="flex flex-row gap-2">
              {invoice?.id && (
                <Button
                  variant="primary-outline"
                  disabled={invoiceTotalDue === 0}
                  onClick={() => form.handleSubmit(onPay)()}
                  loading={loading}
                >
                  Pay Invoice
                </Button>
              )}
              <Button
                variant="primary"
                dataTestId="row-actions-trigger"
                className="sm:col-span-2"
                loading={loading}
                onClick={() => form.handleSubmit(onUpsert)()}
              >
                {confirmText}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
