import { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';
import {
  RequestedTripIdsToReasonCodesMap,
  dismissDispatchModal,
  execTripMenuAction,
} from 'src/features/dispatch/dispatcher.slice';

export const useRejectTripRequestConfirmModal = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const requestedTripIdsToRejectToReasonCodes = useAppSelector(
    (state) => state.dispatcher.modals.requestedTripIdsToRejectToReasonCodes
  );

  const requestActionConfirmModalRowSelection = useAppSelector(
    (state) => state.dispatcher.modals.requestActionConfirmModalRowSelection
  );

  const requestedTripIdsToReject = useMemo(() => {
    return Object.keys(requestedTripIdsToRejectToReasonCodes);
  }, [requestedTripIdsToRejectToReasonCodes]);

  const tripsRepository = useTripRepository();

  const dispatch = useAppDispatch();

  const dismissModal = () => {
    setError(undefined);
    dispatch(dismissDispatchModal());
  };

  const onRejectConfirm = () => {
    dispatch(
      execTripMenuAction({
        action: async () => {
          const confirmedTripIdsToReject =
            requestedTripIdsToReject.length > 1
              ? requestedTripIdsToReject.filter((id) => {
                  return requestActionConfirmModalRowSelection[id];
                })
              : requestedTripIdsToReject;
          if (!confirmedTripIdsToReject.length) {
            setError('One or more trips must be selected to reject');
            throw new Error('No trips to reject!');
          }

          try {
            if (confirmedTripIdsToReject.length > 1) {
              const confirmedTripIdsToRejectToReasonCodes: RequestedTripIdsToReasonCodesMap =
                confirmedTripIdsToReject.reduce((acc, id) => {
                  acc[id] = requestedTripIdsToRejectToReasonCodes[id];
                  return acc;
                }, {} as RequestedTripIdsToReasonCodesMap);
              await tripsRepository.bulkReject(
                confirmedTripIdsToRejectToReasonCodes
              );
            } else {
              const requestedTripId = confirmedTripIdsToReject[0];
              const rejectRequestReason =
                requestedTripIdsToRejectToReasonCodes[requestedTripId] || null;
              await tripsRepository.reject(
                requestedTripId,
                rejectRequestReason
              );
            }
            dismissModal();
          } catch (e) {
            setError(formatServerError(e));
            // TODO: this is a dumb way to not have a success toast when an error occurs
            // Need to enhance the execTripMenuAction to allow custom handling
            throw new Error('No response');
          }
        },
        successMessage: `Trip Request Rejected`,
        errorMessage: `Error rejecting trip request`,
      })
    );
  };

  return {
    error,
    onRejectConfirm,
    dismissModal,
  };
};
