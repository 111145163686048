import { TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

export function DeleteModal({
  open,
  setOpen,
  onCancel,
  onConfirm,
  hasInvoice,
  invoiceNumber,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel: () => void;
  onConfirm: () => void;
  hasInvoice?: boolean;
  invoiceNumber?: string | null;
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex justify-center">
        <div
          className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-error-100 text-error-700"
          style={{
            border: '8px solid #FEF3F2',
            borderRadius: '28px',
          }}
        >
          <TrashIcon className="w-5" />
        </div>
      </div>
      <h4 className="mt-4 text-center text-lg font-medium text-gray-900">
        Delete this trip
      </h4>
      <p className="max-w-xs text-sm text-gray-500">
        Are you sure you want to delete this trip?
        {hasInvoice ? (
          <span className="mt-1 block">
            This trip is part of invoice number: {invoiceNumber}, deleting it
            will also remove it from that invoice.
          </span>
        ) : null}
        <span className="mt-1 block">This action cannot be undone.</span>
      </p>
      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <Button className="sm:col-span-3" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="sm:col-span-3"
          type="submit"
          variant="primary-danger"
          onClick={onConfirm}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
}
