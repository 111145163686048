import { bambiApi } from 'src/common/external/bambi-api/bambiApi';

// https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints
// TODO: use this to remove as much of post-process.js as possible
const enhancedBambiApi = bambiApi.enhanceEndpoints({
  endpoints: {
    dispatchRetrieve: {
      providesTags: ['dispatch', 'trips', 'assignments'],
    },
    tripsValidatePartialUpdate: {
      // We don't want to actually invalidate trips, dispatch, or run-bambi-run tagged queries on this update
      // See https://bambihealth.atlassian.net/browse/BAM-793 and related prs for more context
      // This essentially prevents a race condition where /dispatch/ is invalidated after a /validate/ call
      // and /dispatch/ is fetched before a /assign/ or /reassign/ call completes. The /assign/ and /reassign/ calls
      // sometimes invalidate /dispatch/ too close to the /dispatch/ call and rtk-query will not refetch /dispatch/
      // This results in a stale ui
      invalidatesTags: [],
    },
    organizationSettingsPartialUpdate: {
      invalidatesTags: ['organizations', 'user'],
    },
    invoicesInvoicesCreate: {
      invalidatesTags: ['billing', 'invoices'],
    },
    tripsMultiloadCreate: {
      invalidatesTags: ['dispatch', 'trips'],
    },
    tripsMultiloadPartialUpdate: {
      invalidatesTags: ['dispatch', 'trips'],
    },
    tripsMultiloadDestroy: {
      invalidatesTags: ['dispatch', 'trips'],
    },
    tripsUnassignPartialUpdate: {
      invalidatesTags: ['dispatch', 'trips'],
    },
    tripsBulkUnassignCreate: {
      invalidatesTags: ['dispatch', 'trips'],
    },
    // This doesn't actually change data
    tripsBulkValidateCreate: {
      invalidatesTags: [],
    },
    tripTagsCreate: {
      invalidatesTags: ['dispatch', 'trips', 'tags'],
    },
    tripTagsDestroy: {
      invalidatesTags: ['dispatch', 'trips', 'tags'],
    },
  },
});

export { enhancedBambiApi };
