import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';

// Logic from https://bambihealth.atlassian.net/browse/BAM-612
type PresentStatusEnum = Extends<
  OverrideStatusEnum,
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
>;

// Momentm trips are allowed to be rejected after accepted, assigned
type MomentmPresentStatusEnum = Extends<
  OverrideStatusEnum,
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
  | 'rejected'
>;

export const ALLOWED_PRESENT_TRIP_STATUSES: PresentStatusEnum[] = [
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
];

const ALLOWED_MOMENTM_PRESENT_TRIP_STATUSES: MomentmPresentStatusEnum[] = [
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
  'rejected',
];

type PastStatusEnum = Extends<OverrideStatusEnum, 'completed'>;
export const ALLOWED_PAST_TRIP_STATUSES: PastStatusEnum[] = ['completed'];

type FutureStatusEnum = Extends<OverrideStatusEnum, 'rejected'>;

export function useAllowableNextTripStatuses(tripId: string) {
  const [allowableNextTripStatuses, setAllowableNextTripStatuses] = useState<
    | PresentStatusEnum[]
    | PastStatusEnum[]
    | FutureStatusEnum[]
    | MomentmPresentStatusEnum[]
  >([]);

  const tripRepository = useTripRepository();

  useEffect(() => {
    tripRepository.getTrip(tripId).then((trip) => {
      if (!trip) return;
      const startOfDayDateTime = DateTime.local().startOf('day');
      const pickupDateTime = DateTime.fromISO(trip.scheduled_pickup_at);

      if (pickupDateTime < startOfDayDateTime) {
        setAllowableNextTripStatuses(ALLOWED_PAST_TRIP_STATUSES);
        return;
      }

      // Allow Momentm trips to be rejected after accepted and assigned, but not after
      if (
        trip.intermediary === 'momentm' &&
        ['requested', 'assigned', 'pending'].includes(trip.status)
      ) {
        return setAllowableNextTripStatuses(
          ALLOWED_MOMENTM_PRESENT_TRIP_STATUSES
        );
      }

      setAllowableNextTripStatuses(ALLOWED_PRESENT_TRIP_STATUSES);
    });
  }, [tripRepository, tripId]);

  // Extends above protects us from adding non-allowed statuses
  // Not sure why ts didn't complain about this
  return allowableNextTripStatuses as OverrideStatusEnum[];
}
