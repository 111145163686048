export function ReportContentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <dl className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
      {children}
    </dl>
  );
}
