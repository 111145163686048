import { useCallback, useEffect, useMemo } from 'react';

import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { defaultCurrencyFormat } from 'src/common/defaultCurrencyFormat';
import {
  InvoiceNestedLineItemRead,
  InvoicePayment,
  InvoiceRead,
  useBillingBillingSettingsNextInvoiceNumberRetrieveQuery,
  useInvoicesInvoicesCreateMutation,
  useInvoicesInvoicesPartialUpdateMutation,
  useInvoicesPaymentsCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { show } from 'src/common/primitives/Toast/toast.slice';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { isCreateMode } from './forms/InvoiceForm';
import {
  editInvoice,
  resetEditing,
  setHydratedInvoice,
  setInvoiceModalOpen,
  setPayModalOpen,
} from './invoice.slice';
import { InvoiceModal } from './modals/InvoiceModal';
import { PaymentModal } from './modals/PaymentModal';
import { HydratedInvoice, useHydratedInvoice } from './useHydratedInvoice';

const DUPLICATE_TRIP_ERROR = 'unique_trip_trip_line_item_type';

function dehydrateInvoice(invoice: HydratedInvoice): InvoiceRead {
  return {
    ...invoice,
    line_items: invoice.line_items
      .map((lineItem) => {
        if (lineItem.line_item_type === 'price_summary') {
          return null;
        }

        const item: InvoiceNestedLineItemRead = {
          id: lineItem.id,
          description: lineItem.description,
          amount_cents: lineItem.amount_cents,
          trip: lineItem.trip.id,
          line_item_type: lineItem.line_item_type,
          invoice: lineItem.invoice,
          created_at: '',
          updated_at: '',
          trip_payer: null,
        };
        return item;
      })
      // TS is unable to resolve the result of this filter, the resulting array
      // won't contain null values
      .filter((item) => item !== null) as InvoiceNestedLineItemRead[],
  };
}

export default function InvoiceController() {
  const isModalOpen = useAppSelector((state) => state.invoice.showInvoiceModal);
  const showPaymentModal = useAppSelector(
    (state) => state.invoice.showPayModal
  );
  const editingInvoice = useAppSelector(
    (state) => state.invoice.editingInvoice
  );
  const dispatch = useAppDispatch();
  const handleSetModalOpen = useCallback(
    (open: boolean) => {
      dispatch(setInvoiceModalOpen(open));
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    dispatch(resetEditing());
    dispatch(setInvoiceModalOpen(false));
  }, [dispatch]);

  const [createInvoice, { isLoading: isCreatingInvoice }] =
    useInvoicesInvoicesCreateMutation();
  const [updateInvoice, { isLoading: isUpdatingInvoice }] =
    useInvoicesInvoicesPartialUpdateMutation();
  const [capturePayment, { isLoading: isPayingInvoice }] =
    useInvoicesPaymentsCreateMutation();

  const { isLoading: isLoadingNextInvoiceNumber, data: invoiceNumberData } =
    useBillingBillingSettingsNextInvoiceNumberRetrieveQuery({});

  const nextInvoiceNumber = useMemo(() => {
    if (isLoadingNextInvoiceNumber || !invoiceNumberData) {
      return '';
    }

    return `${invoiceNumberData.invoice_prefix}${invoiceNumberData.next_invoice_number}`;
  }, [isLoadingNextInvoiceNumber, invoiceNumberData]);

  const handleCreateInvoice = useCallback(
    async (invoice: HydratedInvoice, skipReset?: boolean) => {
      try {
        const dehydratedInvoice = dehydrateInvoice(invoice);
        const createdInvoice = await createInvoice({
          invoice: {
            ...dehydratedInvoice,
            date_issued: DateTime.now().toISODate(),
          },
        }).unwrap();

        dispatch(
          show({
            type: 'success',
            title: `${invoice.number} - Created`,
          })
        );

        dispatch(editInvoice(createdInvoice));

        if (!skipReset) {
          dispatch(resetEditing());
        }
      } catch (e) {
        let formattedError = formatServerError(e, true);
        if (formattedError.includes(DUPLICATE_TRIP_ERROR)) {
          formattedError = 'One or more trips are already invoiced.';
        }

        dispatch(
          show({
            type: 'error',
            title: `Failed to create invoice`,
            description: formattedError,
          })
        );
      }
    },
    [createInvoice, dispatch]
  );

  const handleUpdateInvoice = useCallback(
    async (data: HydratedInvoice, skipReset?: boolean) => {
      // Shouldn't happen
      if (!editingInvoice) {
        return;
      }

      const dehydratedInvoice = dehydrateInvoice(data);

      try {
        const updatedInvoice = await updateInvoice({
          id: editingInvoice.id,
          patchedInvoice: dehydratedInvoice,
        }).unwrap();

        dispatch(
          show({
            type: 'success',
            title: `${editingInvoice.number} - Updated`,
          })
        );

        dispatch(editInvoice(updatedInvoice));
        if (!skipReset) {
          dispatch(resetEditing());
        }
      } catch (e) {
        let formattedError = formatServerError(e, true);
        if (formattedError.includes(DUPLICATE_TRIP_ERROR)) {
          formattedError = 'One or more trips are already invoiced.';
        }

        dispatch(
          show({
            type: 'error',
            title: `Failed to update invoice`,
            description: formattedError,
          })
        );
      }
    },
    [editingInvoice, updateInvoice, dispatch]
  );

  const handleUpsertInvoice = useCallback(
    async (data: HydratedInvoice, skipReset?: boolean) => {
      // Editing invoice is null on create
      if (isCreateMode(editingInvoice)) {
        await handleCreateInvoice(data, skipReset);
      } else {
        await handleUpdateInvoice(data, skipReset);
      }
    },
    [editingInvoice, handleCreateInvoice, handleUpdateInvoice]
  );

  const handleShowPay = useCallback(
    (open: boolean) => {
      dispatch(setPayModalOpen(open));
    },
    [dispatch]
  );

  const handleUpdateAndShowPay = useCallback(
    async (invoice: HydratedInvoice) => {
      await handleUpdateInvoice(invoice, true);
      handleShowPay(true);
    },
    [handleShowPay, handleUpdateInvoice]
  );

  const handleCapturePayment = useCallback(
    async (invoicePayment: InvoicePayment) => {
      try {
        await capturePayment({
          invoicePayment,
        }).unwrap();
        dispatch(
          show({
            type: 'info',
            title: 'Payment processing',
            description: `Payment of ${defaultCurrencyFormat(
              invoicePayment.amount_cents
            )} is processing for invoice ${editingInvoice?.number}`,
          })
        );
        handleShowPay(false);
        dispatch(resetEditing());
      } catch (e) {
        dispatch(
          show({
            type: 'error',
            title: 'Payment failed',
            description: `Failed to capture payment: ${formatServerError(e)}`,
          })
        );
      }
    },
    [capturePayment, dispatch, editingInvoice?.number, handleShowPay]
  );

  // Hydrate trip and pricing summary on line items
  const { data: hydratedInvoice, isLoading: isLoadingHydratedInvoice } =
    useHydratedInvoice(editingInvoice ?? null);

  // Setup form and hydrated line items
  const form = useForm<HydratedInvoice>({
    defaultValues: {
      date_issued: hydratedInvoice?.date_issued ?? DateTime.now().toISODate(),
      notes: hydratedInvoice?.notes,
      number: hydratedInvoice?.number ?? nextInvoiceNumber,
      line_items: hydratedInvoice?.line_items ?? [],
    },
  });

  useEffect(
    () => {
      if (!isLoadingHydratedInvoice) {
        form.setValue('line_items', hydratedInvoice?.line_items ?? []);
        dispatch(setHydratedInvoice(hydratedInvoice));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hydratedInvoice, isLoadingHydratedInvoice]
  );

  return (
    <FormProvider {...form}>
      <InvoiceModal
        key={`invoice-${editingInvoice?.id}-${isModalOpen}`}
        open={isModalOpen}
        setOpen={handleSetModalOpen}
        onCancel={handleCancel}
        onUpsert={handleUpsertInvoice}
        onPay={(invoice) => handleUpdateAndShowPay(invoice)}
        confirmText={
          isCreateMode(editingInvoice) ? 'Create Invoice' : 'Update Invoice'
        }
        loading={isCreatingInvoice || isUpdatingInvoice}
        initialLoading={isLoadingNextInvoiceNumber || isLoadingHydratedInvoice}
        nextInvoiceNumber={nextInvoiceNumber}
      />

      <PaymentModal
        key={`payment-${editingInvoice?.id}-${showPaymentModal}`}
        invoice={editingInvoice ?? null}
        open={showPaymentModal}
        setOpen={handleShowPay}
        loading={isPayingInvoice}
        onConfirm={handleCapturePayment}
      />
    </FormProvider>
  );
}
