import {
  GenderEnum,
  ServiceTypeEnum,
  SpaceTypeEnum,
} from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from './FormValues';

export const defaultFormValues: FormValues = {
  id: '',
  dispatcher_notes: '',
  start_date: '',
  expires_at: '',
  pickup: {
    address: { label: '', value: '' },
    address_details: '',
    stairs: 0,
    stair_equipment: undefined,
    contact_name: '',
    contact_phone_number: '',
    driver_notes: '',
    load_time_seconds: 0,
  },
  dropoff: {
    address: { label: '', value: '' },
    address_details: '',
    stairs: 0,
    stair_equipment: undefined,
    contact_name: '',
    contact_phone_number: '',
    driver_notes: '',
    unload_time_seconds: 0,
  },
  must_provide_wheelchair: false,
  passenger: {
    id: '',
    first_name: '',
    last_name: '',
    dob: '',
    gender: '' as GenderEnum,
    weight: 0,
    needs_bariatric_transport: false,
    phone_number: '',
    email: '',
    notes: '',
    organization_id: '',
    created_at: '',
    updated_at: '',
    full_name: '',
  },
  pricing: {
    payer: undefined,
    trip_id: undefined,
    authorization_id: undefined,
    payment_method: undefined,
    payer_passenger_external_id: undefined,
  },
  service_details: {
    space_type: 'wheelchair' as SpaceTypeEnum,
    service_type: 'curb-to-curb' as ServiceTypeEnum,
    is_oxygen_required: false,
    oxygen_liters_per_min: '0',
    num_attendants_needed: 0,
    num_accompanying_passengers: 0,
    has_infectious_disease: false,
  },
  booked_by_name: '',
  booked_by_phone_number: '',
  booked_by_phone_extension: '',
  recurrences: [],
  tags: [],
};
