import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as RechartBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type dataConfigRecords = Record<'categoryLabel', string>;

export type BarChartProps<DataKeys extends string> = {
  data: (Record<DataKeys, number> | dataConfigRecords)[];
  dataKeysList: DataKeys[];
  dataKeysConfigMap: Record<
    DataKeys,
    {
      fill: string;
      activeFill: string;
    }
  >;
};

export function BarChart<DataKeys extends string>({
  data,
  dataKeysList,
  dataKeysConfigMap,
}: BarChartProps<DataKeys>) {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <RechartBarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="categoryLabel" padding={{ left: 15 }} />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeysList.map((dataKey) => (
          <Bar
            key={dataKey}
            dataKey={dataKey}
            fill={dataKeysConfigMap[dataKey].fill}
          />
        ))}
      </RechartBarChart>
    </ResponsiveContainer>
  );
}
