import { Controller, useFormContext } from 'react-hook-form';

import {
  InvoiceNestedLineItemWithId,
  useInvoiceFormContext,
} from '../InvoiceFormContext';

interface LineItemDescriptionDisplayCellProps {
  lineItem: InvoiceNestedLineItemWithId;
}

export function LineItemDescriptionDisplayCell({
  lineItem,
}: LineItemDescriptionDisplayCellProps) {
  const { control } = useFormContext();

  const { findLineItemIndex } = useInvoiceFormContext();
  const actualIndex = findLineItemIndex(lineItem);

  return (
    <Controller
      control={control}
      name={`line_items.${actualIndex}.description`}
      render={({ field }) => {
        return <span>{field.value}</span>;
      }}
    />
  );
}
