import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useAppSelector } from 'src/app/store';
import {
  useTagsListQuery,
  useTripsRetrieveQuery,
  useTripTagsCreateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal } from 'src/common/primitives/Modal';
import { SelectOptionWithColor } from 'src/common/primitives/Select';
import { MultiSelect } from 'src/common/primitives/Select/MultiSelect';
import formatServerError from 'src/common/util/serverErrorFormatter';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { tripSlice } from '../trip.slice';
import { selectOptionsToTripTripTags } from './selectOptionsToTripTags';

export default function TripTagModal() {
  const dispatch = useDispatch();
  const { tripTagModal } = useAppSelector((state) => state.trip);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [options, setOptions] = useState<SelectOptionWithColor[]>([]);
  const [selectedTags, setSelectedTags] = useState<SelectOptionWithColor[]>([]);
  const [saveTripTags] = useTripTagsCreateMutation();

  const { isAssociate, isRideOrderingPortalUser } = useUserRoles();

  const {
    data: selectedTrip,
    isLoading: isTripLoading,
    isFetching: isTripFetching,
    error: tripError,
  } = useTripsRetrieveQuery(
    {
      id: tripTagModal.tripId,
    },
    {
      skip: !tripTagModal.tripId,
    }
  );

  const { data: tags } = useTagsListQuery(
    {},
    {
      skip: isAssociate || isRideOrderingPortalUser,
    }
  );

  const closeModal = () => {
    setSelectedTags([]);
    setError(null);
    dispatch(tripSlice.actions.onCloseTripTags());
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!selectedTags.length) {
        setError('Please select at least one tag');
        setLoading(false);
        return;
      }

      const tripTripTags = selectOptionsToTripTripTags(
        selectedTags,
        tags?.results || [],
        selectedTrip
      );

      await saveTripTags({
        tripTagBulkCreate: { tags: tripTripTags },
      }).unwrap();
      closeModal();
    } catch (error) {
      const message = formatServerError(error);
      setError(message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (tags?.results.length) {
      if (selectedTrip?.tags?.length) {
        const tagsNotOnTrip = tags.results.filter(
          (tag) => !selectedTrip.tags?.find((tripTag) => tripTag.tag === tag.id)
        );

        setOptions(
          tagsNotOnTrip.map((tag) => ({
            label: tag.tag_name,
            value: tag.id,
            color: tag.color_hex || '',
          }))
        );
      } else {
        setOptions(
          tags.results.map((tag) => ({
            label: tag.tag_name,
            value: tag.id,
            color: tag.color_hex || '',
          }))
        );
      }
    }
  }, [selectedTrip?.tags, tags]);

  return (
    <Modal
      open={tripTagModal.isOpen}
      setOpen={closeModal}
      contentClassnames="max-w-2xl md:min-w-[400px]"
    >
      <h1 className="mb-4 text-2xl">Add Trip Tags</h1>
      {tripError && (
        <div className="mb-4 max-w-[550px]">
          <FormErrorMessage>Failed to load existing trip tags</FormErrorMessage>
        </div>
      )}
      {error && (
        <div className="mb-4 max-w-[550px]">
          <FormErrorMessage>{error}</FormErrorMessage>
        </div>
      )}
      {(isTripLoading || isTripFetching) && (
        <div className="flex justify-center">
          <LoadingIndicator />
        </div>
      )}
      <MultiSelect
        options={options}
        selected={selectedTags}
        onChange={(selected) => {
          setSelectedTags(selected as SelectOptionWithColor[]);
        }}
        disabled={loading || isTripLoading || isTripFetching}
      />
      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button
          disabled={loading || isTripLoading || isTripFetching}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || isTripLoading || isTripFetching}
          variant="primary"
          onClick={() => handleSubmit()}
        >
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </Modal>
  );
}
