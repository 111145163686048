import { usePayersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { PayerReportFactory } from 'src/test/factories/reports/PayerReportFactory';

import { PayerReport } from './PayerReport';

export function usePayerReports() {
  // Just using this to show a loading state
  const { isLoading, isFetching } = usePayersListQuery({});
  const payerReports: PayerReport[] = PayerReportFactory.buildList(100);
  return { payerReports, isLoading, isFetching };
}
