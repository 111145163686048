import { useContext } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { classNames } from 'src/common/classNames';

import NavBadge from '../NavBadge';
import { TopLevelNavItemContext } from './TopLevelNavItemContext';

export function DesktopNavLinkList() {
  const navigation = useContext(TopLevelNavItemContext);
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div className="flex space-x-2">
      {navigation.map((item) => (
        <div key={item.name} className="relative">
          <NavLink
            to={item.href}
            className={(isActive) =>
              classNames(
                matchPath({ path: item.href, end: false }, currentPath)
                  ? 'bg-mint-light text-indigo'
                  : 'text-gray-700 hover:bg-mint-light hover:text-indigo',
                'relative flex items-center rounded-md px-3 py-2 text-sm font-medium'
              )
            }
            aria-current={
              matchPath({ path: item.href, end: false }, currentPath)
                ? 'page'
                : undefined
            }
          >
            {item.name}
            {!!item.badgeCount && (
              <div className="absolute -top-2 -right-2">
                <NavBadge count={item.badgeCount} />
              </div>
            )}
            {item.showAttentionIndicator && (
              <ExclamationCircleIcon className="ml-0.5  h-5 w-5" />
            )}
          </NavLink>
        </div>
      ))}
    </div>
  );
}
