import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DriverMembershipFilter } from 'src/common/connectedFilters/DriverMembershipFilter';
import PayersFilter from 'src/common/connectedFilters/PayersFilter';
import { DateRangeFilter } from 'src/common/date/DateRangeFilter';
import { Button } from 'src/common/primitives/Button';

import { reportsSlice } from './reports.slice';

export function ReportsFilters() {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.reports.filters);
  const isFiltered =
    filters.dateRange.from ||
    filters.dateRange.to ||
    filters.payerIds.length > 0 ||
    filters.driverIds.length > 0;
  return (
    <div data-testid="reports-filters" className="flex items-center gap-2">
      <PayersFilter
        selected={filters.payerIds}
        onApply={(payers) => {
          dispatch(reportsSlice.actions.setSelectedPayers(payers));
        }}
      />
      <DriverMembershipFilter
        selected={filters.driverIds}
        onChange={(value) => {
          dispatch(reportsSlice.actions.setSelectedDrivers(value));
        }}
      />
      <DateRangeFilter
        dateRange={filters.dateRange}
        onDateRangeChange={(dateRange) => {
          dispatch(reportsSlice.actions.setDateRange(dateRange));
        }}
        dataTestId="reports-date-range-filter"
      />
      {isFiltered && (
        <Button
          onClick={() => dispatch(reportsSlice.actions.clearFilters())}
          variant="anchor"
          className="whitespace-nowrap"
        >
          clear filters
        </Button>
      )}
    </div>
  );
}
