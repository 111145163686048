import { Controller, useFormContext } from 'react-hook-form';

import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';
import { TextInput } from 'src/common/primitives/TextInput';

import {
  InvoiceNestedLineItemWithId,
  useInvoiceFormContext,
} from '../InvoiceFormContext';

interface LineItemDescriptionEditCellProps {
  lineItem: InvoiceNestedLineItemWithId;
}
export function LineItemDescriptionEditCell({
  lineItem,
}: LineItemDescriptionEditCellProps) {
  const { control } = useFormContext<InvoiceRead>();

  const { findLineItemIndex } = useInvoiceFormContext();
  const actualIndex = findLineItemIndex(lineItem);

  return (
    <Controller
      control={control}
      name={`line_items.${actualIndex}.description`}
      render={({ field }) => {
        return (
          <TextInput
            type="text"
            value={field.value}
            placeholder="Enter item description"
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
          />
        );
      }}
    />
  );
}
