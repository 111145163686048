import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { TripRead } from 'src/common/external/bambi-api/bambiApi';
import {
  dropoffAddressColumnDef,
  passengerColumnDef,
  payerColumnDef,
  pickupAddressColumnDef,
  pickupTimeColumnDef,
  scheduledPickupAtColumnDef,
} from 'src/features/trip/management/columns';

import { TripReasonCell } from './TripReasonCell';

const columnHelper = createColumnHelper<TripRead>();
export const bulkCancelTripModalColummns: ColumnDef<TripRead, any>[] = [
  passengerColumnDef,
  scheduledPickupAtColumnDef,
  pickupTimeColumnDef,
  pickupAddressColumnDef,
  dropoffAddressColumnDef,
  payerColumnDef,
  columnHelper.display({
    header: 'Reason',
    id: 'cancellation_reason',
    cell: ({ row }) => {
      return <TripReasonCell tripId={row.original.id} />;
    },
  }),
];
