import { useMemo } from 'react';

import { InvoiceRead } from 'src/common/external/bambi-api/bambiApi';

import { HydratedTripLineItem } from './forms/cells/types';

export function useInvoiceTotal(
  invoice?: InvoiceRead | null,
  lineItems?: HydratedTripLineItem[]
) {
  const activeLineItems = useMemo(
    () =>
      (
        (lineItems ?? invoice?.line_items ?? []) as HydratedTripLineItem[]
      ).filter((item) => item.line_item_type !== 'price_summary'),
    [invoice?.line_items, lineItems]
  ) as { amount_cents: number }[]; // Simplifying type due to union

  const invoiceTotal = useMemo(() => {
    return activeLineItems.reduce((acc: number, item) => {
      return (acc += item.amount_cents);
    }, 0);
  }, [activeLineItems]);

  const invoiceTotalDue = useMemo(() => {
    const paymentsTotal =
      invoice?.payments?.reduce((acc: number, payment) => {
        return (acc += payment.amount_total_cents);
      }, 0) ?? 0;

    return invoiceTotal - paymentsTotal;
  }, [invoiceTotal, invoice?.payments]);

  return {
    invoiceTotal,
    invoiceTotalDue,
  };
}
