import { useEffect } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import PayersFilter from 'src/common/connectedFilters/PayersFilter';
import DateRangeBadge from 'src/common/date/DateRangeBadge';
import DateRangePicker from 'src/common/date/DateRangePicker';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';
import { getStorageValue } from 'src/common/util/useLocalStorage/getStorageValue';

import { payersStorageKey } from '../trip.slice';
import {
  setSelectedDateRange,
  setSelectedPayers,
  setShowTripSubscriptionModal,
  setTripSubscriptionSearchTerm,
} from './trip-subscriptions.slice';

export function TripSubscriptionsRouteActions() {
  const dispatch = useDispatch();

  const { selectedDateRange, selectedPayers, tripSubscriptionSearchTerm } =
    useSelector((state: RootState) => state.tripSubscription);

  // Transfer stored filters into state on mount, we only want this to happen once per mount
  useEffect(() => {
    const storedPayersFilter = getStorageValue<string[]>(
      payersStorageKey,
      [],
      true
    );

    if (storedPayersFilter.length) {
      dispatch(setSelectedPayers(storedPayersFilter));
    }

    // Ignoring exhaustive-deps warning because I don't want to reset every time
    // the value changes, just on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onPayersApply = (payers: string[]) => {
    dispatch(setSelectedPayers(payers));
  };

  const handleClearClick = () => {
    dispatch(setSelectedDateRange(undefined));
    dispatch(setSelectedPayers([]));
  };

  const handleCreateSubscriptionClick = () => {
    dispatch(setShowTripSubscriptionModal(true));
  };

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setTripSubscriptionSearchTerm,
    tripSubscriptionSearchTerm
  );

  return (
    <div className="flex items-center gap-2 pt-4">
      <DateRangePicker
        triggerElement={
          <Button>
            <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" /> Date
          </Button>
        }
        onDateRangeChange={(dateRange) => {
          const to = dateRange.to?.toISOString() || undefined;
          const from = dateRange.from?.toISOString() || undefined;
          dispatch(setSelectedDateRange({ from, to }));
        }}
      />
      <PayersFilter
        selected={selectedPayers}
        onApply={(payers) => {
          onPayersApply(payers);
        }}
      />
      <SearchTypeahead
        onChange={handleInputChange}
        placeholder="Search trip subscriptions"
        value={inputValue}
      />

      {selectedDateRange?.from && selectedDateRange.to && (
        <div>
          <DateRangeBadge
            from={selectedDateRange.from}
            to={selectedDateRange.to}
            onClear={() => dispatch(setSelectedDateRange(undefined))}
          />
        </div>
      )}

      {selectedPayers.length > 0 || selectedDateRange?.from ? (
        <Button
          className="border-none text-xs text-blue-600 underline"
          onClick={handleClearClick}
        >
          clear filters
        </Button>
      ) : null}

      <Button variant="primary" onClick={handleCreateSubscriptionClick}>
        Create Subscription
      </Button>
    </div>
  );
}
