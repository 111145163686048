import colors from 'src/common/theme/colors';

import { BarChart } from '../metric-components/BarChart';
import { DonutChart } from '../metric-components/DonutChart';
import { MetricCard } from '../metric-components/MetricCard';
import { ProgressBarMetricCard } from '../metric-components/ProgressBarMetricCard';
import { ReportContentLayout } from '../ReportContentLayout';

const hardCodedDonutCellData: {
  id: string;
  name: string;
  value: number;
  backgroundColor: string;
}[] = [
  {
    id: 'canceled',
    name: 'Canceled',
    value: 400,
    backgroundColor: colors.trip.canceled.bullet,
  },
  {
    id: 'completed',
    name: 'Completed',
    value: 600,
    backgroundColor: colors.trip.complete.bullet,
  },
];

const dataKeysList = ['canceled', 'completed'];
const dataKeysConfigMap = {
  canceled: {
    fill: colors.trip.canceled.bullet,
    activeFill: colors.trip.canceled.bg,
  },
  completed: {
    fill: colors.trip.complete.bullet,
    activeFill: colors.trip.complete.bg,
  },
};

const data = [
  {
    categoryLabel: '10-09-2024',
    canceled: 4000,
    completed: 2400,
  },
  {
    categoryLabel: '11-09-2024',
    canceled: 3000,
    completed: 1398,
  },
  {
    categoryLabel: '12-09-2024',
    canceled: 2000,
    completed: 9800,
  },
  {
    categoryLabel: '12-09-2024',
    canceled: 2780,
    completed: 3908,
  },
  {
    categoryLabel: '12-09-2024',
    canceled: 1890,
    completed: 4800,
  },
  {
    categoryLabel: '12-09-2024',
    canceled: 2390,
    completed: 3800,
  },
  {
    categoryLabel: '12-09-2024',
    canceled: 3490,
    completed: 4300,
  },
];

export default function ReportsContent() {
  return (
    <ReportContentLayout>
      <MetricCard title="Estimated Revenue" formattedValue="$1,000,000" />
      <ProgressBarMetricCard
        title="Percent On-time"
        formattedValue="90%"
        progressPercentage={90}
        partLabel="900 trips on-time"
        totalLabel="1000 trips total"
      />
      <ProgressBarMetricCard
        title="Estimated Mileage"
        formattedValue="900 loaded / 100 unloaded"
        progressPercentage={90}
        partLabel="900 loaded miles"
        totalLabel="1000 total miles"
      />
      <MetricCard title="Trip Status">
        <DonutChart cells={hardCodedDonutCellData} />
      </MetricCard>
      <MetricCard title="Trip Status">
        <BarChart
          dataKeysList={dataKeysList}
          dataKeysConfigMap={dataKeysConfigMap}
          data={data}
        />
      </MetricCard>
    </ReportContentLayout>
  );
}
