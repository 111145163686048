import { useMemo } from 'react';

import {
  MembershipRead,
  useMembersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { FilterPopoverTrigger } from 'src/common/primitives/FilterPopover/FilterPopoverTrigger';
import { MultiSelectFilterPopover } from 'src/common/primitives/FilterPopover/MultiSelectFilterPopover';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { formatFullName } from 'src/common/util/formatFullName';

export function DriverMembershipFilter({
  selected,
  onChange,
}: {
  selected: string[];
  onChange: (value: string[]) => void;
}) {
  const { data, isLoading, isFetching } = useMembersListQuery({
    isDriver: true,
    ordering: 'first_name',
  });

  const selectedValues: MembershipRead[] | undefined = useMemo(() => {
    return data?.results.filter((v) => selected.includes(v.id.toString()));
  }, [data, selected]);

  return (
    <MultiSelectFilterPopover
      value={selectedValues}
      filters={data?.results ?? []}
      renderLabel={(driver) => formatFullName(driver)}
      onChange={(value) => {
        onChange(value.map((d) => d.id));
      }}
      triggerElement={
        <FilterPopoverTrigger
          count={selectedValues?.length || 0}
          label={
            isFetching || isLoading ? (
              <LoadingIndicator variant="small" />
            ) : (
              'Driver'
            )
          }
        />
      }
    />
  );
}
