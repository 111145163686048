import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import {
  PatchedTripWrite,
  TripLocationDetail,
} from 'src/common/external/bambi-api/bambiApi';

import { FormValues } from '../FormValues';

export const adaptFormValuesToUpdate = (
  formValues: FormValues
): PatchedTripWrite => {
  let allowSms: string | boolean | undefined =
    formValues.passenger.allow_sms_message ?? false;
  // Facility users aren't able to read this value, so ensure its not updated
  if (typeof allowSms === 'string') {
    allowSms = undefined;
  }

  return {
    booked_by_name: formValues.booked_by_name,
    booked_by_phone_number: formValues.booked_by_phone_number,
    booked_by_phone_extension: formValues.booked_by_phone_extension,
    passenger: {
      id: formValues.passenger.id,
      first_name: formValues.passenger.first_name,
      last_name: formValues.passenger.last_name,
      gender: formValues.passenger.gender,
      weight: Number(formValues.passenger.weight),
      needs_bariatric_transport: formValues.passenger.needs_bariatric_transport,
      email:
        formValues.passenger.email === null ? '' : formValues.passenger.email,
      notes: formValues.passenger.notes,
      ...(formValues.passenger.dob && { dob: formValues.passenger.dob }),
      ...(formValues.passenger.phone_number && {
        phone_number: formValues.passenger.phone_number,
      }),
      allow_sms_message: allowSms,
    },
    external_trip_id: formValues.trips[0].external_trip_id,
    payer_id: formValues.pricing.payer,
    payer_passenger_external_id: formValues.pricing.payer_passenger_external_id,
    input_price_cents: formValues.trips[0].input_price_cents || 0,
    is_price_locked: formValues.trips[0].is_price_locked,
    payment_method: formValues.pricing.payment_method,
    pickup: {
      place_id: formValues.trips[0].pickupLocation.address.value,
      address_details: formValues.trips[0].pickupLocation.address_details,
      num_stairs: formValues.trips[0].pickupLocation.stairs,
      stair_equipment: formValues.trips[0].pickupLocation.stair_equipment,
      contact_name: formValues.trips[0].pickupLocation.contact_name,
      contact_phone_number: formValues.trips[0].pickupLocation
        .contact_phone_number
        ? formValues.trips[0].pickupLocation.contact_phone_number
        : null,
      driver_notes: formValues.trips[0].pickupLocation.driver_notes,
    } as TripLocationDetail,
    dropoff: {
      place_id: formValues.trips[0].dropoffLocation.address.value,
      address_details: formValues.trips[0].dropoffLocation.address_details,
      num_stairs: formValues.trips[0].dropoffLocation.stairs,
      stair_equipment: formValues.trips[0].dropoffLocation.stair_equipment,
      contact_name: formValues.trips[0].dropoffLocation.contact_name,
      contact_phone_number: formValues.trips[0].dropoffLocation
        .contact_phone_number
        ? formValues.trips[0].dropoffLocation.contact_phone_number
        : null,
      driver_notes: formValues.trips[0].dropoffLocation.driver_notes,
    } as TripLocationDetail,
    scheduled_pickup_at: convertISODateTimePartsToUTCISO({
      date: formValues.trips[0].timing.date,
      time: formValues.trips[0].timing.pickup_time,
    }),
    appointment_at: formValues.trips[0].timing.appointment_time
      ? convertISODateTimePartsToUTCISO({
          date: formValues.trips[0].timing.date,
          time: formValues.trips[0].timing.appointment_time,
        })
      : null,
    is_will_call: formValues.trips[0].timing.is_will_call,
    space_type: formValues.service_details.space_type,
    service_type: formValues.service_details.service_type,
    dispatcher_notes: formValues.trips[0].dispatcher_notes,
    is_oxygen_required: formValues.service_details.is_oxygen_required,
    oxygen_liters_per_min: String(
      formValues.service_details.oxygen_liters_per_min
    ),
    num_attendants_needed: formValues.service_details.num_attendants_needed,
    num_accompanying_passengers:
      formValues.service_details.num_accompanying_passengers,
    load_time_seconds: formValues.trips[0].load_time_seconds
      ? formValues.trips[0].load_time_seconds
      : null,
    has_infectious_disease: formValues.service_details.has_infectious_disease,
    unload_time_seconds: formValues.trips[0].unload_time_seconds
      ? formValues.trips[0].unload_time_seconds
      : null,
    tag_ids: formValues.trips[0].trip_tags.map((option) => option.value),
    authorization_id: formValues.pricing.authorization_id,
    insurance_policy_id: formValues.pricing.insurance_policy_id,
    must_provide_wheelchair: formValues.must_provide_wheelchair,
  };
};
