import { useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';

import { HydratedTripLineItem } from './cells/types';
import { allColumns } from './columns';
import { InvoiceNestedLineItemWithId } from './InvoiceFormContext';

interface InvoiceLineItemListProps {
  lineItems: InvoiceNestedLineItemWithId[];
  onDeleteLineItem: (rowIndex: number, row: HydratedTripLineItem) => void;
}

export function InvoiceLineItemList({
  lineItems,
  onDeleteLineItem,
}: InvoiceLineItemListProps) {
  const editingLineItemGroup = useAppSelector(
    (state) => state.invoice.editingLineItemGroup
  );

  return (
    <DataGrid
      tableId="invoice-line-item"
      columns={allColumns}
      data={lineItems}
      includePagination={false}
      headerClassName="bg-slate-100"
      cellClassName="align-top"
      containerClassName="print:max-w-[100vw]"
      isEditingRow={(row) => row.trip.id === editingLineItemGroup}
      enableGridScrolling={false}
      onDeleteRow={(rowIndex, row) => {
        const lineItemToDelete = lineItems.find((item) => item.id === row.id);
        if (!lineItemToDelete) {
          return;
        }

        onDeleteLineItem(rowIndex, lineItemToDelete);
      }}
    />
  );
}
