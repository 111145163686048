import { ToolbarAndContentLayout } from 'src/common/primitives/ToolbarAndContentLayout';

import { ReportsFilters } from '../ReportsFilters';
import { ReportsNav } from '../ReportsNav';
import { PayerReportsContent } from './PayerReportsContent';

export function PayerReportsRoute() {
  return (
    <ToolbarAndContentLayout
      preContent={<ReportsNav />}
      content={<PayerReportsContent />}
      actionSection={<ReportsFilters />}
    />
  );
}
