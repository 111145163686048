import { useCallback, useEffect, useState } from 'react';

import { useFeature } from 'src/app/FeatureToggle';
import {
  InvoiceNestedLineItem,
  InvoiceRead,
  LineItemTypeEnum,
  useLazyTripsPriceSummaryRetrieveQuery,
  useLazyTripsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';

import { HydratedTripLineItem } from './forms/cells/types';

export interface HydratedInvoice extends Omit<InvoiceRead, 'line_items'> {
  line_items: HydratedTripLineItem[];
}

type UseHydratedInvoice = {
  isLoading: boolean;
  data: HydratedInvoice | null;
  error: string | null;
};

export function useHydratedInvoice(
  invoice: InvoiceRead | null
): UseHydratedInvoice {
  const [isLoading, setLoading] = useState(true);
  const [hydratedInvoice, setHydratedInvoice] = useState<HydratedInvoice>();
  const {
    isEnabled: isPricingFeatureEnabled,
    isLoading: isPricingfeatureLoading,
  } = useFeature('pricing version 1');
  const [error, setError] = useState(null);

  const hydrate = useInvoiceHydrator(invoice, isPricingFeatureEnabled);

  useEffect(() => {
    if (!invoice || isPricingfeatureLoading) {
      return;
    }

    setLoading(true);

    hydrate()
      .then((result) => {
        // No items were able to be hydrated
        if (!result) {
          setHydratedInvoice({
            ...invoice,
            line_items: [],
          });
          return;
        }

        // Filter out line items that couldn't be hydrated, can happen when trip is deleted after invoiced
        const lineItems: HydratedTripLineItem[] = [];

        result.forEach((settledItem) => {
          if (settledItem.status === 'rejected') {
            return;
          }

          lineItems.push(settledItem.value as HydratedTripLineItem);
        });

        setHydratedInvoice({
          ...invoice,
          line_items: lineItems,
        });
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, hydrate, invoice, isPricingfeatureLoading]);

  if (!invoice) {
    return {
      isLoading: false,
      data: null,
      error: null,
    };
  }

  return {
    isLoading,
    data: hydratedInvoice ?? null,
    error,
  };
}

interface PricingSummaryLineItemStub
  extends Omit<InvoiceNestedLineItem, 'line_item_type'> {
  line_item_type?: LineItemTypeEnum | 'price_summary';
  created_at: string;
}

export function useInvoiceHydrator(
  invoice: InvoiceRead | null,
  isPricingFeatureEnabled: boolean
) {
  const [fetchTrip] = useLazyTripsRetrieveQuery();
  const [fetchTripPriceSummary] = useLazyTripsPriceSummaryRetrieveQuery();

  const hydrate = useCallback(() => {
    if (!invoice) {
      return Promise.resolve();
    }

    // Pregenerate list with pricing summary item
    const items: Array<InvoiceNestedLineItem | PricingSummaryLineItemStub> = [];
    invoice.line_items.forEach((item) => {
      items.push(item);

      if (item.line_item_type === 'trip' && isPricingFeatureEnabled) {
        items.push({
          ...item,
          line_item_type: 'price_summary',
          created_at: new Date().toLocaleString(),
        });
      }
    });

    return Promise.allSettled(
      items.map(async (item) => {
        if (!item.trip) {
          return item;
        }

        const tripResult = await fetchTrip({ id: item.trip }, true).unwrap();
        const pricingResult =
          isPricingFeatureEnabled && item.line_item_type === 'price_summary'
            ? await fetchTripPriceSummary({ id: item.trip }, true).unwrap()
            : null;

        return {
          ...item,
          trip: tripResult,
          invoice: invoice.id,
          price_summary: pricingResult,
        };
      })
    );
  }, [fetchTrip, fetchTripPriceSummary, invoice, isPricingFeatureEnabled]);

  return hydrate;
}
