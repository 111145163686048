import { DateTime } from 'luxon';

import { adaptAssignmentsToEpg } from './adaptAssignmentsToEpg';
import { AssignmentManagementDisplayProps } from './AssignmentManagementDisplay';
import { theme } from './theme';

export function buildPlanByConfig({
  vehicles,
  assignments,
  assignmentDate,
}: AssignmentManagementDisplayProps) {
  const epg = adaptAssignmentsToEpg(assignments);
  // planby hates `yyyy/MM/dd` format but only on December 31st of any year
  // so if for any reason you change startDate/endDate format, make sure to test it on December 31st
  // Added a specific test for this in AssignmentManagementDisplay.test.tsx
  const startDate = assignmentDate.toUTC().toISO();
  const endDate = assignmentDate.plus({ day: 1 }).toUTC().toISO();

  return {
    epg,
    channels: vehicles.map((vehicle) => {
      return {
        logo: '',
        uuid: vehicle.id,
      };
    }),
    isBaseTimeFormat: true,
    theme,
    // Must supply both of these or planby will barf
    startDate,
    endDate,
    itemHeight: 80,
    sidebarWidth: 320,
    dayWidth: 3000,
    isTimeline: true,
    isCurrentTime: false,
    isLine: true,
    timezone: {
      enabled: true,
      zone: DateTime.local().zoneName,
      mode: 'utc',
    },
  };
}
