import { useMemo } from 'react';

import { groupBy } from 'lodash-es';

import { HydratedTripLineItem } from './forms/cells/types';

export function useGroupLineItems(lineItems: HydratedTripLineItem[]) {
  const lineItemsByTrip = useMemo(
    () => groupBy(lineItems, (lineItem) => lineItem.trip.id),
    [lineItems]
  );

  return lineItemsByTrip;
}
