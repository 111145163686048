import { useMemo } from 'react';

import {
  BulkTripRejectWrite,
  NewStatusEnum,
  PatchedTripWrite,
  TripsBulkCreateWrite,
  useLazyTripsRetrieveQuery,
  useTripsAcceptPartialUpdateMutation,
  useTripsActivateWillCallPartialUpdateMutation,
  useTripsAssignPartialUpdateMutation,
  useTripsBulkRejectCreateMutation,
  useTripsCancelPartialUpdateMutation,
  useTripsCompletePartialUpdateMutation,
  useTripsCreateMutation,
  useTripsDestroyMutation,
  useTripsLockPartialUpdateMutation,
  useTripsOverrideStatusPartialUpdateMutation,
  useTripsPartialUpdateMutation,
  useTripsReassignPartialUpdateMutation,
  useTripsRejectPartialUpdateMutation,
  useTripsStatusPartialUpdateMutation,
  useTripsUnassignPartialUpdateMutation,
  useTripsUncancelPartialUpdateMutation,
  useTripsUnlockPartialUpdateMutation,
  useTripsUpdatePickupAtPartialUpdateMutation,
  useTripsValidatePartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { RequestedTripIdsToReasonCodesMap } from 'src/features/dispatch/dispatcher.slice';

import { getRevertedStatusForTrip } from './getRevertedStatusForTrip';

export function useTripRepository() {
  const [getTripImpl] = useLazyTripsRetrieveQuery();
  const [createTripImpl] = useTripsCreateMutation();
  const [updateTripImpl] = useTripsPartialUpdateMutation();
  const [destroyTripImpl] = useTripsDestroyMutation();
  const [assignImpl] = useTripsAssignPartialUpdateMutation();
  const [reassignImpl] = useTripsReassignPartialUpdateMutation();
  const [unassignImpl] = useTripsUnassignPartialUpdateMutation();
  const [cancelImpl] = useTripsCancelPartialUpdateMutation();
  const [uncancelImpl] = useTripsUncancelPartialUpdateMutation();
  const [completeImpl] = useTripsCompletePartialUpdateMutation();
  const [lockImpl] = useTripsLockPartialUpdateMutation();
  const [unlockImpl] = useTripsUnlockPartialUpdateMutation();
  const [statusImpl] = useTripsStatusPartialUpdateMutation();
  const [activateWillCallImpl] =
    useTripsActivateWillCallPartialUpdateMutation();
  const [updatePickupAtImpl] = useTripsUpdatePickupAtPartialUpdateMutation();
  const [validateTripAssignmentImpl] = useTripsValidatePartialUpdateMutation();
  const [overrideTripStatusImpl] =
    useTripsOverrideStatusPartialUpdateMutation();
  const [bulkRejectImpl] = useTripsBulkRejectCreateMutation();
  const [rejectImpl] = useTripsRejectPartialUpdateMutation();
  const [acceptImpl] = useTripsAcceptPartialUpdateMutation();

  const repository = useMemo(() => {
    return {
      getTrip: async (tripId: string) => {
        return getTripImpl({
          id: tripId,
        }).unwrap();
      },
      createTrip: async (tripsBulkCreate: TripsBulkCreateWrite) => {
        return createTripImpl({
          tripsBulkCreate,
        }).unwrap();
      },
      updateTrip: async (tripId: string, patchedTrip: PatchedTripWrite) => {
        return updateTripImpl({
          id: tripId,
          patchedTrip,
        }).unwrap();
      },
      destroyTrip: async (tripId: string) => {
        return destroyTripImpl({
          id: tripId,
        }).unwrap();
      },
      validateAssignment: async (tripId: string, assignmentId: string) => {
        return validateTripAssignmentImpl({
          id: tripId,
          patchedTripValidate: {
            assignment_id: assignmentId,
          },
        }).unwrap();
      },
      assign: async (tripId: string, assignmentId: string) => {
        return assignImpl({
          id: tripId,
          patchedTripAssign: {
            assignment_id: assignmentId,
          },
        }).unwrap();
      },
      reassign: async (tripId: string, assignmentId: string) => {
        return reassignImpl({
          id: tripId,
          patchedTripReassign: {
            assignment_id: assignmentId,
          },
        }).unwrap();
      },
      unassign: async (tripId: string) => {
        return unassignImpl({
          id: tripId,
        }).unwrap();
      },
      cancel: async (tripId: string, reason?: string) => {
        return cancelImpl({
          id: tripId,
          patchedTripCancel: {
            canceled_reason: reason,
          },
        }).unwrap();
      },
      uncancel: async (tripId: string) => {
        return uncancelImpl({
          id: tripId,
          patchedTripUncancel: {
            // Mostly a mobile app concept so including this for type
            concurrent_trips: [],
          },
        }).unwrap();
      },
      complete: async (tripId: string) => {
        return completeImpl({
          id: tripId,
          patchedTripComplete: {
            // Mostly a mobile app concept so including this for type
            concurrent_trips: [],
            override: false,
          },
        }).unwrap();
      },
      completeWithNoValidations: async (tripId: string) => {
        return completeImpl({
          id: tripId,
          patchedTripComplete: {
            // Mostly a mobile app concept so including this for type
            concurrent_trips: [],
            override: true,
          },
        }).unwrap();
      },
      lock: async (tripId: string) => {
        return lockImpl({
          id: tripId,
        }).unwrap();
      },
      unlock: async (tripId: string) => {
        return unlockImpl({
          id: tripId,
        }).unwrap();
      },
      status: async (tripId: string, new_status: NewStatusEnum) => {
        return statusImpl({
          id: tripId,
          patchedTripStatusChange: {
            new_status,
          },
        }).unwrap();
      },
      activateWillCall: async (tripId: string, pickupTime: string) => {
        return activateWillCallImpl({
          id: tripId,
          patchedTripActivateWillCall: {
            pickup_at: pickupTime,
          },
        }).unwrap();
      },
      updatePickupTime: async (tripId: string, newTime: string) => {
        return updatePickupAtImpl({
          id: tripId,
          patchedTripUpdatePickupAt: {
            pickup_at: newTime,
          },
        }).unwrap();
      },
      revertStatus: async (tripId: string) => {
        const trip = await getTripImpl({
          id: tripId,
        }).unwrap();
        const status = trip.status;
        const newStatus: NewStatusEnum = getRevertedStatusForTrip(status);
        return statusImpl({
          id: tripId,
          patchedTripStatusChange: {
            new_status: newStatus,
          },
        }).unwrap();
      },
      overrideStatus: async (tripId: string, newStatus: NewStatusEnum) => {
        return overrideTripStatusImpl({
          id: tripId,
          patchedTripOverrideStatusChange: {
            override_status: newStatus,
          },
        }).unwrap();
      },
      // TODO: This really shouldn't know about RequestedTripIdsToReasonCodesMap
      bulkReject: async (
        requestedTripIdsToRejectToReasonCodes: RequestedTripIdsToReasonCodesMap
      ) => {
        const bulkTripReject: BulkTripRejectWrite = {
          trip_reasons: Object.keys(requestedTripIdsToRejectToReasonCodes).map(
            (tripId) => {
              return {
                trip_id: tripId,
                rejected_reason:
                  requestedTripIdsToRejectToReasonCodes[tripId] || 'other',
              };
            }
          ),
        };

        return bulkRejectImpl({
          bulkTripReject,
        }).unwrap();
      },
      reject: async (tripId: string, reason: string | null) => {
        return rejectImpl({
          id: tripId,
          patchedTripReject: {
            // TODO: api actually requires a reason
            rejected_reason: reason || 'other',
          },
        }).unwrap();
      },
      accept: async (tripId: string) => {
        return acceptImpl({
          id: tripId,
          // Not optional, so pass empty
          // Override param is not actually used and will probably break if passed
          // to api.
          patchedTripAccept: {},
        }).unwrap();
      },
    };
  }, [
    acceptImpl,
    activateWillCallImpl,
    assignImpl,
    bulkRejectImpl,
    cancelImpl,
    completeImpl,
    createTripImpl,
    destroyTripImpl,
    getTripImpl,
    lockImpl,
    overrideTripStatusImpl,
    reassignImpl,
    rejectImpl,
    statusImpl,
    unassignImpl,
    uncancelImpl,
    unlockImpl,
    updatePickupAtImpl,
    updateTripImpl,
    validateTripAssignmentImpl,
  ]);
  return repository;
}
