export const saveResponseUsingHiddenElement = (
  response: any,
  fileName: string
) => {
  const hiddenElement = document.createElement('a');
  const url = (window.URL as typeof window.URL | undefined) || window.webkitURL;

  const blob = url.createObjectURL(response?.data as Blob);
  hiddenElement.href = blob;
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
};
