import { DateTime } from 'luxon';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import { Popover } from 'src/common/primitives/Popover';

import { BrowserJSDateToOrgLuxonDateTime } from './BrowserJSDateToOrgLuxonDateTime';
import { OrgLuxonDateTimeToBrowserJSDate } from './OrgLuxonDateTimeToBrowserJSDate';

const MIN_JS_DATE = new Date(-8640000000000000);
const MAX_JS_DATE = new Date(8640000000000000);

export function DatePicker({
  triggerElement,
  value,
  onChange,
  maxDate,
  minDate,
}: {
  triggerElement: React.ReactNode;
  value?: DateTime;
  onChange: (date: DateTime | undefined) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
}) {
  // timeZone is set in useAuth line 99
  const timeZone = DateTime.local().zoneName;

  const convertToJSDate = (date?: DateTime) =>
    date ? OrgLuxonDateTimeToBrowserJSDate(date) : undefined;

  const valueAsJSDate = convertToJSDate(value);
  const minDateAsJSDate = convertToJSDate(minDate) || MIN_JS_DATE;
  const maxDateAsJSDate = convertToJSDate(maxDate) || MAX_JS_DATE;

  return (
    <>
      <style>{`
      .rdp-root {
        --rdp-accent-color: #0CACA6;
        --rdp-accent-background-color: rgba(12, 172, 166, 0.15);
      }
    `}</style>
      <Popover
        trigger={triggerElement}
        content={
          <DayPicker
            mode="single"
            timeZone={timeZone}
            selected={valueAsJSDate}
            onDayClick={(day) => {
              const luxonDateTime = BrowserJSDateToOrgLuxonDateTime(day);
              onChange(luxonDateTime);
            }}
            disabled={{ before: minDateAsJSDate, after: maxDateAsJSDate }}
            today={OrgLuxonDateTimeToBrowserJSDate(DateTime.local())}
          />
        }
      ></Popover>
    </>
  );
}
