import { useAppDispatch, useAppSelector } from 'src/app/store';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormFieldSelect } from 'src/common/FormField/FormFieldSelect';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import {
  dismissDispatchModal,
  setModalSelectedTrip,
  useDispatchModalToShow,
} from 'src/features/dispatch/dispatcher.slice';

import { useCancellationOptions } from '../useCancellationOptions';
import { useCancellationLogic } from './useCancellationLogic';

export default function CancelTripModal() {
  const dispatch = useAppDispatch();
  const open = useDispatchModalToShow() === 'cancelTrip';
  const { selectedTrip } = useAppSelector((state) => state.dispatcher.modals);

  const closeModal = () => {
    setReason('');
    setError('');
    dispatch(setModalSelectedTrip(null));
    dispatch(dismissDispatchModal());
  };

  const { cancellationReasonOptions } = useCancellationOptions(selectedTrip);

  const { reason, setReason, error, setError, loading, handleCancelTrip } =
    useCancellationLogic(selectedTrip, closeModal);

  let brokerWarningMessage = null;

  if (
    selectedTrip?.intermediary_id &&
    (selectedTrip.intermediary === 'momentm' ||
      selectedTrip.intermediary === 'kinetik')
  ) {
    brokerWarningMessage = (
      <span>
        <strong>Warning: </strong>
        This trip cannot be uncancelled due to Broker restrictions.
      </span>
    );
  }

  return (
    <Modal
      open={open}
      setOpen={(val) => {
        if (!val) {
          closeModal();
        }
      }}
      contentClassnames="max-w-6xl"
    >
      <h1 className="py-4 text-center text-2xl">Confirm Trip Cancel</h1>
      {brokerWarningMessage}

      {error && (
        <div className="my-2">
          <FormErrorMessage>{error}</FormErrorMessage>
        </div>
      )}

      <FormFieldSelect
        label="Please provide a cancellation reason:"
        inputProps={{
          required: true,
        }}
        type="select"
        options={cancellationReasonOptions}
        onChange={(option) => {
          setReason(option.value);
        }}
        disabled={loading}
      />

      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button disabled={loading} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={!reason}
          variant="primary"
          onClick={handleCancelTrip}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
}
