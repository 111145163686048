import { useCallback, useMemo } from 'react';

import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { SearchTypeahead } from 'src/common/SearchTypeahead';
import { useDebouncedSearch } from 'src/common/useDebouncedSearch';

import { useUserRoles } from '../auth/useUserRoles';
import { TripManagementFilters } from './management/TripManagementFilters';
import {
  setShowBulkCancelTripModal,
  setShowDownloadModal,
  setShowUploadDetailsModal,
  setTripSearchTerm,
} from './trip.slice';

export function TripIndexRouteActions() {
  const dispatch = useAppDispatch();
  const { isRestrictedSupport } = useUserRoles();

  const { selectedTripRows, tripSearchTerm } = useAppSelector(
    (state) => state.trip
  );

  const selectedTripRowsList = useMemo(() => {
    return Object.keys(selectedTripRows).filter((id) => !!selectedTripRows[id]);
  }, [selectedTripRows]);

  const handleDownload = useCallback(() => {
    dispatch(setShowDownloadModal(true));
  }, [dispatch]);

  const handleUpload = useCallback(() => {
    dispatch(setShowUploadDetailsModal(true));
  }, [dispatch]);

  const { inputValue, handleInputChange } = useDebouncedSearch(
    setTripSearchTerm,
    tripSearchTerm
  );

  const handleCancelTrips = useCallback(() => {
    dispatch(setShowBulkCancelTripModal(true));
  }, [dispatch]);

  return (
    <div className="flex flex-row items-end gap-2">
      <Button
        className={selectedTripRowsList.length ? '' : 'hidden'}
        ariaHidden={!selectedTripRowsList.length}
        variant="danger"
        onClick={handleCancelTrips}
        dataTestId="bulk-cancel-trips-button"
      >
        <XCircleIcon className="mr-2 h-5 w-5" /> Cancel (
        {`${selectedTripRowsList.length}`}) Trip(s)
      </Button>
      <TripManagementFilters />
      <div className="flex flex-row gap-3">
        <SearchTypeahead
          onChange={handleInputChange}
          placeholder="Search trips"
          value={inputValue}
        />
        <Button onClick={handleDownload}>
          <CloudArrowDownIcon className="mr-2 h-5 w-5" /> Download
        </Button>
        {!isRestrictedSupport && (
          <Button onClick={handleUpload}>
            <CloudArrowUpIcon className="mr-2 h-5 w-5" /> Upload
          </Button>
        )}
      </div>
    </div>
  );
}
