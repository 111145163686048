import { XMarkIcon } from '@heroicons/react/24/outline';
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { classNames } from 'src/common/classNames';

import NavBadge from './NavBadge';

type TabNavigationProps = {
  tabs: {
    id?: string;
    name: string;
    href: string;
    badgeCount?: number;
  }[];
  showDeleteIcon?: boolean;
  onDelete?: (id: string) => void;
};

export function TabNavigation({
  tabs,
  showDeleteIcon,
  onDelete,
}: TabNavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  return (
    <div>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
          defaultValue={
            tabs.find((tab) => matchPath({ path: tab.href }, currentPath))?.name
          }
          onChange={(e) => {
            const nextHref = e.target.value;
            navigate(nextHref);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.id ?? tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex items-center space-x-8">
            {tabs.map((tab) => (
              <div
                key={tab.id ?? tab.name}
                className={classNames(
                  matchPath({ path: tab.href }, currentPath)
                    ? 'is-active border-primary-700 text-primary-700'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group flex cursor-pointer items-center space-x-2 whitespace-nowrap border-b-2 text-sm font-medium ',
                  showDeleteIcon && 'pr-6 hover:pr-0'
                )}
              >
                <NavLink
                  data-testid={`tab-navigation-link-${tab.name}`}
                  to={tab.href}
                  end
                  className="py-4 px-1"
                >
                  {tab.name}
                </NavLink>
                {tab.badgeCount && <NavBadge count={tab.badgeCount} />}
                {showDeleteIcon && (
                  <XMarkIcon
                    data-testid="tab-navigation-delete-icon"
                    className="hidden h-4 w-4 text-gray-500 group-hover:block"
                    onClick={() => tab.id && onDelete && onDelete(tab.id)}
                  />
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
