import { useCallback } from 'react';

import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { Button } from 'src/common/primitives/Button';
import { Well } from 'src/common/primitives/Well/Well';

import { editLineItems } from '../invoice.slice';
import { HydratedTripLineItem } from './cells/types';
import { InvoiceNestedLineItemWithId } from './InvoiceFormContext';
import { InvoiceLineItemList } from './InvoiceLineItemList';

interface InvoiceLineItemContainerProps {
  lineItems: InvoiceNestedLineItemWithId[];
  onAddLineItem: (item: HydratedTripLineItem) => void;
  onDeleteLineItem: (rowIndex: number, row: HydratedTripLineItem) => void;
}

export function InvoiceLineItemContainer({
  lineItems,
  onAddLineItem,
  onDeleteLineItem,
}: InvoiceLineItemContainerProps) {
  const trip = lineItems[0].trip;
  const dispatch = useAppDispatch();
  const editingLineItem = useAppSelector(
    (state) => state.invoice.editingLineItemGroup
  );

  const handleAddTripLineItem = useCallback(() => {
    onAddLineItem({
      trip,
      amount_cents: 0,
      description: '',
      line_item_type: 'modifier',
      invoice: '',
    });
  }, [trip, onAddLineItem]);

  const handleEditLineItems = useCallback(() => {
    if (editingLineItem === trip.id) {
      dispatch(editLineItems(''));
    } else {
      dispatch(editLineItems(trip.id));
    }
  }, [trip, dispatch, editingLineItem]);

  return (
    <Well testId={`well-for-trip-${trip.id}`}>
      <div className="flex w-full items-center justify-end gap-2">
        <Button
          dataTestId="line-item-group-edit-button"
          className="flex gap-2 print:hidden"
          onClick={handleEditLineItems}
        >
          <PencilIcon className="w-5" />
          Edit
        </Button>
        <Button
          className="flex gap-2 print:hidden"
          dataTestId="line-item-group-delete-button"
          onClick={() => onDeleteLineItem(0, lineItems[0])}
        >
          <TrashIcon className="w-5" />
          Delete
        </Button>
      </div>
      <InvoiceLineItemList
        lineItems={lineItems}
        onDeleteLineItem={onDeleteLineItem}
      />
      {editingLineItem === trip.id ? (
        <div className="mt-2">
          <Button
            dataTestId="line-item-container-add-subitem-button"
            variant="secondary"
            className="flex gap-2 print:hidden"
            onClick={handleAddTripLineItem}
          >
            <PlusCircleIcon className="w-5" />
            Add a subitem
          </Button>
        </div>
      ) : null}
    </Well>
  );
}
