import { Controller, useFormContext } from 'react-hook-form';

import { useFeature } from 'src/app/FeatureToggle';

import {
  InvoiceNestedLineItemWithId,
  useInvoiceFormContext,
} from '../InvoiceFormContext';
import { CellAligner } from './CellAligner';
import { currencyFormatter } from './utils';

interface PriceDisplayCellProps {
  isTrip: boolean;
  tripId: string;
  displayPrice?: number;
  lineItem: InvoiceNestedLineItemWithId;
}
export function PriceDisplayCell({
  isTrip,
  tripId,
  lineItem,
  displayPrice,
}: PriceDisplayCellProps) {
  const {
    isEnabled: isPricingFeatureEnabled,
    isLoading: isPricingFeatureLoading,
  } = useFeature('pricing version 1');

  const { control } = useFormContext();
  const { findLineItemIndex } = useInvoiceFormContext();
  const actualIndex = findLineItemIndex(lineItem);

  if (isPricingFeatureLoading && isTrip) {
    return null;
  }

  return (
    <Controller
      control={control}
      name={`line_items.${actualIndex}.amount_cents`}
      render={({ field }) => {
        const price = displayPrice ?? field.value;

        const priceCellValue = (
          <div
            className="text-right font-mono text-gray-800"
            data-testid={`${tripId}-trip-price`}
          >
            {currencyFormatter(price / 100)}
          </div>
        );

        if (!isTrip) {
          return priceCellValue;
        }

        if (!isPricingFeatureEnabled) {
          return <CellAligner>{priceCellValue}</CellAligner>;
        }

        return <span />;
      }}
    />
  );
}
