import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';

export function useUpdateFormValuesFromPassenger({
  passenger,
}: {
  passenger?: PassengerRead | null;
}): void {
  const { setValue } = useFormContext();
  useEffect(() => {
    if (!passenger) return;

    setValue('passenger.id', passenger.id);
    setValue('passenger.first_name', passenger.first_name);
    setValue('passenger.last_name', passenger.last_name);
    setValue('passenger.dob', passenger.dob);
    setValue('passenger.gender', passenger.gender);
    setValue('passenger.weight', passenger.weight);
    setValue(
      'passenger.needs_bariatric_transport',
      passenger.needs_bariatric_transport
    );
    setValue('passenger.phone_number', passenger.phone_number);
    setValue('passenger.email', passenger.email);
    setValue('passenger.notes', passenger.notes);
    setValue('passenger.save_as_new_passenger', !passenger.id || false);
    setValue('payer_ids', passenger.payer_ids || []);
    setValue(
      'passenger.allow_sms_message',
      passenger.allow_sms_message ?? false
    );
  }, [passenger, setValue]);
}
