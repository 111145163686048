import { useMemo } from 'react';

import { useAppSelector } from 'src/app/store';
import { TripRead } from 'src/common/external/bambi-api/bambiApi';

export function useSelectedTripRows() {
  const selectedTripRowsInState = useAppSelector(
    (state) => state.trip.selectedTripRows
  );

  const selectedTripRows = useMemo(() => {
    return Object.values(selectedTripRowsInState).filter(
      (trip) => trip !== null
    ) as TripRead[];
  }, [selectedTripRowsInState]);

  return selectedTripRows;
}
