import React, { createContext, useContext } from 'react';

import { HydratedTripLineItem } from './cells/types';

// When managing the list of line_items we gain an id from hook form
export type InvoiceNestedLineItemWithId = HydratedTripLineItem & {
  __id: string;
};

interface InvoiceFormContextState {
  findLineItemIndex: (item: InvoiceNestedLineItemWithId) => number;
}

const InvoiceFormContext = createContext<InvoiceFormContextState>({
  findLineItemIndex: () => {
    return -1;
  },
});

export const useInvoiceFormContext = () => useContext(InvoiceFormContext);

export function InvoiceFormContextProvider({
  children,
  findLineItemIndex,
}: {
  children: React.ReactNode;
  findLineItemIndex: (item: InvoiceNestedLineItemWithId) => number;
}) {
  return (
    <InvoiceFormContext.Provider
      value={{
        findLineItemIndex,
      }}
    >
      {children}
    </InvoiceFormContext.Provider>
  );
}
