import { useDriversListQuery } from 'src/common/external/bambi-api/bambiApi';
import { DriverReportFactory } from 'src/test/factories/reports/DriverReportFactory';

import { DriverReport } from './DriverReport';

export function useDriverReports() {
  // Just using this to show a loading state
  const { isLoading, isFetching } = useDriversListQuery({});
  const driverReports: DriverReport[] = DriverReportFactory.buildList(100);
  return { driverReports, isLoading, isFetching };
}
