import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { usePayersandtemplatesRetrieveQuery } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { Select, SelectOption } from 'src/common/primitives/Select';
import {
  selectUploadPayerId,
  selectUploadPayerTemplate,
  setShowUploadDetailsModal,
  setShowUploadModal,
} from 'src/features/trip/trip.slice';

import { UploadDetailsSampleDownloadFile } from './UploadDetailsSample';

export function UploadDetailsModal() {
  const dispatch = useDispatch();

  const open = useSelector(
    (state: RootState) => state.trip.showUploadDetailsModal
  );

  const [payer, setPayer] = useState<SelectOption | undefined>(undefined);
  const [payerOptions, setPayerOptions] = useState<SelectOption[] | undefined>(
    undefined
  );
  const [payerTemplate, setPayerTemplate] = useState<SelectOption | undefined>(
    undefined
  );
  const [payerTemplateOptions, setPayerTemplateOptions] = useState<
    SelectOption[] | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { data: payers } = usePayersandtemplatesRetrieveQuery(
    {},
    { skip: !open }
  );

  const closeModal = () => {
    setError(undefined);
    setLoading(false);
    dispatch(setShowUploadDetailsModal(false));
    setPayer(undefined);
    setPayerTemplate(undefined);
  };

  const handleSubmit = () => {
    closeModal();
    if (payerTemplate && payer) {
      dispatch(setShowUploadModal(true));
      dispatch(selectUploadPayerTemplate(payerTemplate));
      dispatch(selectUploadPayerId(payer));
    }
  };

  const onPayerChange = (selected: SelectOption | undefined) => {
    if (selected) {
      setPayer(selected);
      if (payer?.value === 'multiple') {
        setPayerTemplate(undefined);
      } else {
        const newPayer = payers?.payer_templates.find(
          (x) => x.id === payer?.value
        );
        if (newPayer?.default_template) {
          setPayerTemplate({
            label: newPayer.default_template.template_name,
            value: newPayer.default_template.template_key,
          });
        } else {
          setPayerTemplate(undefined);
        }
      }
    }
  };

  useEffect(() => {
    if (payers) {
      const singlePayerOptions = payers.payer_templates
        .filter((payer) => payer.active_templates.length > 0)
        .map((payer) => {
          return {
            label: payer.payer_name,
            value: payer.id,
          };
        });

      const payerOptions = payers.multi_payer_templates.length
        ? [
            ...singlePayerOptions,
            { label: '', value: 'select-separator' },
            { label: 'Multiple Payers', value: '' },
          ]
        : singlePayerOptions;

      setPayerOptions(payerOptions);
    }
  }, [payers]);

  useEffect(() => {
    if (payer) {
      if (payer.value === '') {
        const multiPayerTemplates = payers?.multi_payer_templates.map(
          (template) => {
            return {
              label: template.template_name,
              value: template.template_key,
            };
          }
        );

        setPayerTemplateOptions(multiPayerTemplates);
        if (multiPayerTemplates?.length === 1) {
          setPayerTemplate(multiPayerTemplates[0]);
        }
      } else {
        const payerActiveTemplates = payers?.payer_templates.find(
          (x) => x.id === payer.value
        )?.active_templates;

        const payerTemplates = payerActiveTemplates?.map((template) => {
          return {
            label: template.template_name,
            value: template.template_key,
          };
        });

        setPayerTemplateOptions(payerTemplates);

        if (payerTemplates?.length === 1) {
          setPayerTemplate(payerTemplates[0]);
        }
      }
    }
  }, [payer, payers?.multi_payer_templates, payers?.payer_templates]);

  return (
    <Modal open={open} setOpen={closeModal} contentClassnames="max-w-6xl">
      <h1 className="text-2xl">Select a CSV template</h1>
      <p>You'll upload and verify the data in the next step</p>
      {payer?.label === 'Multiple Payers' ? (
        <UploadDetailsSampleDownloadFile />
      ) : null}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {payerOptions?.length === 0 && (
        <FormErrorMessage>None of your payers have templates.</FormErrorMessage>
      )}
      <div className="flex flex-col gap-3 py-4">
        <div>
          <DefaultFormFieldLayout label="Payer" inputProps={{ id: 'payer' }}>
            <Select
              placeholder="Select payer"
              selected={payer}
              options={payerOptions || []}
              onChange={(selected) => {
                onPayerChange(selected);
              }}
            ></Select>
          </DefaultFormFieldLayout>
        </div>
        <div>
          <DefaultFormFieldLayout
            label="Template"
            inputProps={{ id: 'template' }}
          >
            <Select
              placeholder="Select template"
              selected={payerTemplate}
              options={payerTemplateOptions || []}
              onChange={(selected) => {
                if (selected) setPayerTemplate(selected);
              }}
              disabled={!payer}
            ></Select>
          </DefaultFormFieldLayout>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-6">
        <Button disabled={loading} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="primary"
          onClick={() => handleSubmit()}
        >
          {loading ? 'Uploading' : 'Upload'}
        </Button>
      </div>
    </Modal>
  );
}
