import { createContext } from 'react';

export interface NavItem {
  name: string;
  href: string;
  showAttentionIndicator?: boolean;
  badgeCount?: number;
}

export const TopLevelNavItemContext = createContext<NavItem[]>([]);
TopLevelNavItemContext.displayName = 'TopLevelNavItemContext';
