import { useEffect, useState } from 'react';

import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { DateRange } from 'react-day-picker';

import DateRangePicker from 'src/common/date/DateRangePicker';
import { Button } from 'src/common/primitives/Button';

import DateRangeBadge from './DateRangeBadge';

export function DateRangeFilter({
  dataTestId,
  dateRange,
  onDateRangeChange,
}: {
  dataTestId?: string;
  // TODO: This exposes the DateRange type -- we should probably use a luxon flavored type
  // to be consistent with the rest of the app
  onDateRangeChange: (dateRange: DateRange) => void;
  dateRange?: DateRange;
}) {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    dateRange || { from: undefined, to: undefined }
  );

  useEffect(() => {
    setSelectedDateRange(dateRange || { from: undefined, to: undefined });
  }, [dateRange]);

  return (
    <div className="flex items-center gap-1">
      <DateRangePicker
        triggerElement={
          // TODO: This should be a FilterPopoverTrigger
          // But we should modify to show the selected date range
          <Button dataTestId={dataTestId}>
            <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" /> Date
          </Button>
        }
        onDateRangeChange={(dateRange) => {
          onDateRangeChange(dateRange);
          setSelectedDateRange(dateRange);
        }}
      />
      {selectedDateRange.from && selectedDateRange.to && (
        <div data-testid="date-range-badge">
          <DateRangeBadge
            from={selectedDateRange.from.toISOString()}
            to={selectedDateRange.to.toISOString()}
            onClear={() => {
              onDateRangeChange({ from: undefined, to: undefined });
              setSelectedDateRange({ from: undefined, to: undefined });
            }}
          />
        </div>
      )}
    </div>
  );
}
