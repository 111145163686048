import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useFeature } from 'src/app/FeatureToggle';
import { FormField } from 'src/common/FormField';
import { FormContextField } from 'src/common/FormField/FormContextField';
import { ConfirmModal } from 'src/common/modals/ConfirmModal';

export default function AllowSMSFormContextField() {
  const { setValue } = useFormContext();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { isEnabled: hasPassengerMessageFeature } = useFeature(
    'passenger messaging'
  );

  if (!hasPassengerMessageFeature) {
    return null;
  }

  return (
    <div className="mt-4 sm:col-span-6">
      <FormContextField name="allow_sms_message">
        {({ field, fieldState }) => {
          return (
            <>
              <FormField
                label="Allow SMS Message"
                helperText="Used for trip reminders"
                error={fieldState.error?.message?.toString()}
                type="switch"
                inputProps={{
                  id: 'allow-sms-message',
                  ...field,
                  checked: field.value,
                  onChange: (value) => {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (value) {
                      setShowConfirmModal(true);
                    }
                    field.onChange(value);
                  },
                }}
              />
              <ConfirmModal
                open={showConfirmModal}
                setOpen={() => {
                  // do nothing
                  // make user click confirm or cancel
                }}
                onCancel={() => {
                  setValue('allow_sms_message', false);
                  setShowConfirmModal(false);
                }}
                onConfirm={() => setShowConfirmModal(false)}
                title="Confirm SMS Consent"
                description={
                  'By enabling SMS messaging, you confirm that you have ' +
                  "obtained the passenger's explicit consent to receive " +
                  'text messages. Please ensure compliance with all ' +
                  'applicable laws and regulations before proceeding.'
                }
                confirmText="Yes, allow SMS"
                cancelText="No, don't allow SMS"
                mode="neutral"
              />
            </>
          );
        }}
      </FormContextField>
    </div>
  );
}
