import {
  TripRead,
  useTripsCancelReasonsRetrieveQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export function useCancellationOptions(selectedTrip: TripRead | null) {
  const { data: cancelOptionsData } = useTripsCancelReasonsRetrieveQuery(
    {
      id: selectedTrip?.id || '',
    },
    {
      skip: !selectedTrip?.id,
    }
  );

  return {
    cancellationReasonOptions:
      cancelOptionsData?.cancel_reasons.map((reason) => {
        return {
          label: reason.label,
          value: reason.value || '',
        } as SelectOption;
      }) || [],
  };
}
