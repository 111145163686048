import { HydratedInvoice } from '../useHydratedInvoice';

export function invoiceDefaultValuesFactory(
  invoice?: HydratedInvoice | null
): Partial<HydratedInvoice> {
  return {
    line_items: [],
    notes: '',
    number: '',
    payments: [],
    total_amount_cents: 0,
    total_amount_due_cents: 0,
    date_issued: '',
    ...(invoice ?? {}),
  };
}
