import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRange } from 'react-day-picker';

export interface IReportsState {
  filters: {
    dateRange: DateRange;
    payerIds: string[];
    driverIds: string[];
  };
}

export const initialReportsState: IReportsState = {
  filters: {
    dateRange: {
      from: undefined,
      to: undefined,
    },
    payerIds: [],
    driverIds: [],
  },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialReportsState,
  reducers: {
    setDateRange: (state, action: PayloadAction<DateRange>) => {
      state.filters.dateRange = action.payload;
    },
    setSelectedPayers: (state, action: PayloadAction<string[]>) => {
      state.filters.payerIds = action.payload;
    },
    setSelectedDrivers: (state, action: PayloadAction<string[]>) => {
      state.filters.driverIds = action.payload;
    },
    clearFilters: (state) => {
      state.filters = { ...initialReportsState.filters };
    },
  },
});
