import { useState } from 'react';

import { Button } from 'src/common/primitives/Button';
import { useTripMenuActions } from 'src/features/dispatch/DispatchCalendar/useTripMenuActions';

interface AddPickupWaitTimeProps {
  tripId: string;
  existingWaitTimeSeconds?: number | null;
}

export const quickWaitTimesInMinutes = [5, 10, 15, 30, 45, 60];
export function AddPickupWaitTime({
  tripId,
  existingWaitTimeSeconds,
}: AddPickupWaitTimeProps) {
  const menuActions = useTripMenuActions();
  const [isUpdatingTime, setIsUpdatingTime] = useState(false);
  return (
    <div>
      <div className="grid grid-cols-2 gap-1">
        {quickWaitTimesInMinutes.map((minutes) => (
          <Button
            dataTestId={`add-pickup-wait-time-${minutes}`}
            variant="secondary"
            key={minutes}
            disabled={isUpdatingTime}
            onClick={() => {
              setIsUpdatingTime(true);
              menuActions.updatePickupWaitTime({
                tripId,
                waitTimeInSeconds:
                  minutes * 60 + (existingWaitTimeSeconds || 0),
              });
              setIsUpdatingTime(false);
            }}
          >
            <div className="flex flex-col items-center justify-center">
              <span>{minutes}</span>
              <span>Min</span>
            </div>
          </Button>
        ))}
      </div>
      {existingWaitTimeSeconds && (
        <div className="grid grid-cols-1 gap-1">
          <Button
            className="mt-1"
            dataTestId={`remove-pickup-wait-time`}
            variant="danger"
            disabled={isUpdatingTime}
            onClick={() => {
              setIsUpdatingTime(true);
              menuActions.updatePickupWaitTime({
                tripId,
                waitTimeInSeconds: null,
              });
              setIsUpdatingTime(false);
            }}
          >
            <div className="flex flex-col items-center justify-center">
              <span>Remove Wait Time</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}
