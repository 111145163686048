export default function NavBadge({ count }: { count: number }) {
  return (
    <span
      data-testid="nav-badge"
      className="inline-flex items-center justify-center rounded-full bg-red-600 px-1.5 py-1 text-xs font-bold leading-none text-red-100"
    >
      {count}
    </span>
  );
}
